<template>
    <div v-if="category">
        <app-page-header
            icon="fa fa-object-group"
            :title="'Editar Categoria: ' + category.id"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '/categories', title: 'Categorias' },
                { href: '', title: 'Editar' },
            ]"
        />

        <div class="row">
            <div class="col-md-12">
                <div class="tile">
                    <div class="tile-body">
                        <form ref="form">
                            <div class="row">
                                <div class="col-12 col-xl-2 col-lg-3">
                                    <div class="row">
                                        <app-image
                                            ref="imageManager"
                                            :url="category.thumb"
                                            :default="DEFAULT_IMAGE"
                                            @onLoaded="saveImage"
                                            @onDelete="deleteImage"
                                        />
                                    </div>
                                </div>
                                <app-input-multi
                                    name="title"
                                    placeHolder="Título"
                                    v-model="category.title"
                                    :minLength="1"
                                    :maxLength="65"
                                    :required="true"
									:disabled="saving"
                                />
                                <app-toggle
									label="Status"
									:disabled="saving"
									v-model="category.active"
								/>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-12">
                                    <app-button 
                                        type="update" 
                                        :callback="onSubmit" 
                                        color="primary"
										:disabled="saving"
                                    />
                                    <app-link
										type="back"
										:url="backUrl"
										:disabled="saving"
									/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import InputMulti from '../forms/InputMultiLanguageComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';
    import Toggle from '../forms/ToggleComponent';
    import ImageManager from '../ImageManagerComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-input-multi': InputMulti,
            'app-button': Button,
            'app-link': Link,
            'app-toggle': Toggle,
            'app-image': ImageManager,
        },
        data() {
			return {
				saving: false,
                category: null,
				imageManager: null,
				DEFAULT_IMAGE: '/assets/img/default_thumbs/category.png',
			}
		},
        props: {
			id: String,
			backUrl: String,
		},
        mounted() {
            this.load(this.id);
        },
        methods: {
            load(id) {
                Preloader.show();

                axios.get(`/web/categories/${id}`)
                	.then(async res => {
                        this.category = res.data;

						await Help.delay(300);

                        this.imageManager = this.$refs.imageManager;
                        Preloader.hide();
					})
					.catch(err => {});
			},
            openEditImage() {
                this.$refs.imageSelect.open();
            },
            imageLoaded(image) {
                if (image) {
                    let reader = new FileReader();
                    reader.onload = (e) => {
                       this.tempImage = e.currentTarget.result;
                    }
                    reader.readAsDataURL(image);
                } else {
                    this.tempImage = this.DEFAULT_IMAGE;
                }
            },
			saveImage(image) {
                Preloader.show();

                axios.patch(`/web/categories/${this.id}/image`, { image })
                    .then(res => {
                        Flash('success', 'Image salva com sucesso!');
                        Preloader.hide();
                    })
                    .catch(err => {
                        Flash('error', 'Erro ao tentar salvar imagem!');
                        Preloader.hide();
                    });
            },
            deleteImage() {
                Preloader.show();

                axios.delete(`/web/categories/${this.id}/image`)
                    .then(res => {
                        Flash('success', 'Image removida com sucesso!');
                        this.imageManager.clear();
                        Preloader.hide();
                    })
                    .catch(err => {
                        Flash('error', 'Erro ao tentar remover imagem!');
                        Preloader.hide();
                    });
            },
             async onSubmit() {
				this.saving = true;
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
					this.saving = false;
                } else {					
                    axios.put(`/web/categories/${this.category.id}`, this.category)
                        .then(res => {
                            Swal.fire({
                                title: 'Categoria atualizada com sucesso!',
                                type: 'success'
                            })
                            .then(() => {
                                window.location.href = '/categories';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar atualizar registro!');
							this.saving = false;
                        });
                }
            }
		}
    }
</script>