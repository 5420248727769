var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-user","title":"Jogadores","breadcrumbs":[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Jogadores' },
            ]}}),_vm._v(" "),_c('app-datatable',{ref:"dataTable",attrs:{"entity":"players","allow-enable-multi":_vm.allows.enableMulti,"allow-disable-multi":_vm.allows.disableMulti,"allow-delete-multi":_vm.allows.deleteMulti,"options":{
                ajax: {
                    url: '/web/players'
                },
                columns: this.columns
            },"actions":{
                show: {
                    allow: this.allows.show,
                    url: '*',
                    callback: _vm.onShow
                },
				delete: {
                    allow: this.allows.delete,
                    url: '*',
                },
                performance: {
                    allow: true,
                    url: '*',
                    callback: _vm.onPerformance
                },
            },"filters":[
                {
                    label: 'Escola',
                    name: 'school_id',
                    type: 'select',
                    index: 4,
                    options: _vm.schools,
                    liveSearch: true,
                    callback: _vm.loadClassrooms
                },
                {
                    label: 'Sala',
                    name: 'classroom_id',
                    type: 'select',
                    index: 5,
                    options: _vm.classrooms,
                    liveSearch: true,
                },
                {
                    name: 'player_type',
                    type: 'select',
                    label: 'Tipo de jogador',
                    index: 6,
                    options: [
                        { value: 'mn', label: 'MN' },
                        { value: 'common', label: 'Comum' },
                    ]
                }
            ]}}),_vm._v(" "),_c('app-player-show',{ref:"playerShow"}),_vm._v(" "),_c('app-player-performance',{ref:"playerPerformance"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }