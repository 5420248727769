<template>
	<div>
		<app-page-header
            icon="fa fa-dashboard"
            title="Painel"
            :breadcrumbs="[
				{ href: '', title: 'Painel' }
			]"
        />

		<div class="row">
            <app-jumbotron :name="userName" />
        </div>

		<div class="row">
			<app-widget
				type="success"
				icon="fa fa-user"
				title="Jogadores"
				dataContent="Ver Alunos"
				dataToggle="popover"
				link="/players"
				:value="amountPlayers"
			/>
			<app-widget
				type="primary"
				icon="fa fa-gamepad"
				title="Partidas"
				:value="amountBattles"
			/>
			<app-widget
				type="danger"
				icon="fa fa-trophy"
				title="Torneios"
				dataContent="Ver Torneios"
				dataToggle="popover"
				link="/tournaments"
				:value="amountTournaments"
			/>
			<app-widget
				icon="fa fa-list-ol"
				title="Ranking"
				dataContent="Ver Ranking"
				dataToggle="popover"
				@click="openRanking"
			/>
		</div>
		<div class="row">
			<app-utilization />
			<app-distribution />
			<app-ranking ref="ranking" />
		</div>
	</div>
</template>

<script>
	import PageHeader from '../PageHeaderComponent';
	import Jumbotron from '../JumbotronComponent';
	import Widget from '../WidgetComponent';
	import Utilization from '../UtilizationComponent';
	import Distribution from '../DistributionComponent';
	import Ranking from '../RankingComponent';

	export default {
		components: {
			'app-page-header': PageHeader,
			'app-jumbotron': Jumbotron,
			'app-widget': Widget,
			'app-utilization': Utilization,
			'app-distribution': Distribution,
			'app-ranking': Ranking,
		},
		data() {
			return {
				amountPlayers: 0,
				amountBattles: 0,
				amountTournaments: 0,
			}
		},
		props: {
            userName: String,
        },
		mounted() {
			this.loadPlayers();
			this.loadBattles();
			this.loadTournaments();
		},
		methods: {
			loadPlayers() {
				axios.get(`/report/players/count`)
					.then(res => {
						this.amountPlayers = res.data.count;
					})
					.catch(err => {});
			},
			loadBattles() {
				axios.get(`/report/battles/count`)
					.then(res => {
						this.amountBattles = res.data.count;
					})
					.catch(err => {});
			},
			loadTournaments() {
				axios.get(`/report/tournaments/count`)
					.then(res => {
						this.amountTournaments = res.data.count;
					})
					.catch(err => {});
			},
			openRanking() {
				this.$refs.ranking.open();
			}
		}
	}
</script>