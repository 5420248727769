<template>
    <div>
        <app-page-header
            icon="fa fa-user"
            title="Gerentes"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Gerentes' },
            ]"
        />

        <app-datatable
            entity="managers"
            :allow-enable-multi="allows.enableMulti"
            :allow-disable-multi="allows.disableMulti"
            :allow-delete-multi="allows.deleteMulti"
            :withStatusFilter="true"
            :options="{
                ajax: {
                    url: '/web/managers'
                },
                columns: this.columns
            }"
            :actions="{
                show: {
                    allow: this.allows.show,
                    url: '*',
                    callback: onShow
                },
            }"
        />

        <app-manager-show ref="managerShow" />
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';              
    import DataTable from '../DataTableComponent';
    import ManagerShow from './ManagerShowComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-datatable': DataTable,
            'app-manager-show': ManagerShow
        },
        props: {
			allows: Object,
			columns: Array,
		},
        methods: {
			onShow(id) {
				this.$refs.managerShow.open(id);
			}
		}
    }
</script>