<template>
    <section class="login-content">
		<div class="logo">
	        <h1>
	            <a href="/" :title="title">
	                <img src="/assets/img/logo_email.png" :alt="title">
	            </a>
	        </h1>
	    </div>
        <div class="login-box login-box-recover">
            <form class="login-form" ref="form" autocomplete="off" onsubmit="return false">
                <h3 class="login-head font-weight-normal">Recuperação de Senha</h3>
				<div class="form-group">
					<input
						type="email"
						name="email"
						class="form-control"
						autofocus
						minlength="2"
						maxlength="65"
						placeholder="E-mail"
						required
						v-model="data.email"
						@keyup="onSubmit"
					>
				</div>
                <button
                    type="button"
                    class="btn btn-primary btn-block"
                    @click="onSubmit"
                    :disabled="logging"
                >
            		<span
                        v-if="logging"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    <span v-if="!logging">Enviar</span>
                </button>
                <button
                    type="button"
                    class="btn btn-secondary btn-block"
                    @click="onLogin"
                    :disabled="logging"
                >
                    <span>Entrar</span>
                </button>
            </form>
        </div>
        <h6 class="version text-muted m-0 h6">v.{{ appVersion }}</h6>
    </section>
</template>

<script>
export default {
    data() {
        return {
            logging: false,
            data: {
				email: ''
			}
        }
    },
    props: {
        title: String,
        appVersion: String,
        router: String,
    },
    methods: {
        onSubmit(e = null) {
            if (e instanceof Event && e.keyCode != 13 && e.type != 'click') return;

            this.logging = true;
            let validate = this.validateForm(this.$refs.form);

            if (validate != '') {
                this.logging = false;
                Flash('error', validate);
            } else {
                axios.post('/web/password/email', this.data)
                    .then(res => {
                        this.logging = false;
					
                        Swal.fire({
                            text: `Solicitação enviada com sucesso, um link foi enviado para seu 
							e-mail!`,
                            type: 'success'
                        })
                            .then(() => {
                                window.location.href = '/login';
                            });
                    })
                    .catch(err => {
                        Flash('error', 'E-mail não cadastrado!');
                        this.logging = false;
                    });
            }
        },
        validate(form) {
            cash(form).find('input,select').removeClass('is-invalid');
            cash(form).find('input,select').removeClass('is-valid');

            let result = this.validateForm(form.elements);

            return result;
        },
        onLogin(e) {
            window.location.href = '/login';
        }
    }
}
</script>
