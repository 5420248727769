<template>
	<div
		class="tab-pane fade"
		id="step5"
		role="tabpanel"
	>
		<h3 class="text-center">Fase criada com sucesso!</h3>
		<p class="text-center">
			Você pode criar até 3 fases para seu torneio.
		</p>
		<div class="text-center">
			<button
				class="btn btn-outline-info bt-confirm"
				@click="onClickNewStage"
			>
				CRIAR NOVA FASE
			</button>
		</div>
		<app-arrows
			:prevHide="true"
			@click="onClickArrow"
		/>
	</div>
</template>

<script>
	import Arrows from './ArrowsComponent';

	export default {
		components: {
			'app-arrows': Arrows,
		},
		methods: {
			onClickArrow(direction) {
				this.$emit('click', direction);
			},
			onClickNewStage() {
				this.$emit('clickNewStage');
			}
		}
	}
</script>