var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-trophy","title":"Torneios","breadcrumbs":[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Torneios' },
            ]}}),_vm._v(" "),_c('app-datatable',{attrs:{"entity":"tournaments","allow-enable-multi":_vm.allows.enableMulti,"allow-disable-multi":_vm.allows.disableMulti,"allow-delete-multi":_vm.allows.deleteMulti,"options":{
                ajax: {
                    url: '/web/tournaments'
                },
                columns: this.columns
            },"actions":{
                show: {
                    allow: this.allows.show,
                    url: '*',
                    callback: _vm.onShow
                },
                edit: {
                    allow: this.allows.edit,
                    url: '/tournaments/*/edit',
                },
				delete: {
                    allow: this.allows.delete,
                    url: '*',
                },
                ranking: {
                    allow: this.allows.ranking,
                    url:  '*',
                    callback: _vm.showRanking
                },
                statistics: {
                    allow: this.allows.statistics,
                    url:  '*',
                    callback: _vm.showStatistic
                },
            },"filters":[
                {
                    label: 'Status',
                    name: 'status',
                    index: 4,
                    type: 'select',
                    options: _vm.statusList
                }
            ]}}),_vm._v(" "),_c('app-tournament-show',{ref:"tournamentShow"}),_vm._v(" "),_c('app-tournament-ranking',{ref:"tournamentRanking"}),_vm._v(" "),_c('app-tournament-statistic',{ref:"tournamentStatistic"}),_vm._v(" "),_c('app-wizard',{ref:"wizard"}),_vm._v(" "),(this.allows.create)?_c('app-link',{attrs:{"type":"create","data-toggle":"popover","data-content":"Cadastrar Novo","title":"","onClick":_vm.onCreate}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }