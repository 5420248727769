<template>
    <div>
        <app-page-header
            icon="fa fa-cog"
            title="Menus"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Menus' }
            ]"
        />

        <div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
                        <div class="row">
                            <div
                                v-for="(menu, index) in menus"
                                class="col-12 col-xl-3 col-md-6 px-1"
                            >
                                <div class="list-group mb-2 px-0 shadow-sm accordion">
                                    <a
                                        class="list-group-item list-group-item-secondary"
                                        href="javascript:"
                                        :data-target="'#cards' + index"
                                        data-toggle="collapse"
                                    >
                                        <span v-if="(menu instanceof Array)">
                                            {{ menu[0].group_name }}
                                        </span>
                                        <span v-else>-</span>
                                    </a>
                                    <div
                                        :id="'cards' + index"
                                        class="list-group-item p-2 collapse"
                                    >
                                        <app-menu-card
                                            v-if="(menu instanceof Array)"
                                            v-for="item in menu"
                                            :key="item.id"
                                            :data="item"
                                            :onChange="onChange"
                                            :onEdit="onEdit"
                                            :editable="allows.edit"
                                        />

                                        <app-menu-card
                                            v-if="!(menu instanceof Array)"
                                            :data="menu"
                                            :onChange="onChange"
                                            :onEdit="onEdit"
                                            :editable="allows.edit"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-link
            v-if="allows.create"
            type="create"
            url="/menus/create"
        />
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Link from '../forms/LinkComponent';
    import MenuCard from './MenuCardComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-link': Link,
            'app-menu-card': MenuCard,
        },
        data() {
            return {
                menus: [],
            }
        },
        props: {
            allows: Object,
        },
        mounted() {
            this.load();
        },
        methods: {
            load() {
                Preloader.show();
                axios.get('/web/menus?all=true')
                    .then(res => {
                        this.menus = res.data;
                        Preloader.hide();
                    })
                    .catch(err => {});
            },
            onChange(e) {
                let id = e.target.value;
                let data = {
                    _method: 'PATCH',
                    active: e.target.checked
                };
                
                axios.post(`/web/menus/${id}/changeStatus`, data)
                    .then(res => {
                        Flash('success', 'Registro atualizado!');
                    })
                    .catch(err => {
                        Flash('error', 'Erro ao tentar atualizar registro!');
                    });
            },
            onEdit(e, id) {
                window.location.href = `/menus/${id}/edit`;
            }
        }
    }
</script>