<template>
	<div
		ref="modal"
		class="modal fade"
		tabindex="-1"
		role="dialog"
	>
		<div
			class="modal-dialog modal-dialog-centered modal-lg"
			role="document"
		>
			<div class="modal-content">
				<div class="modal-header">
                    <h5 class="modal-title">
                        <i class="fa fa-signal"></i>
                        {{ title }}
                    </h5>
                    <button 
                        type="button" 
                        class="close" 
                        data-dismiss="modal" 
                        aria-label="Close"
                        @click="onClose"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
				<div class="modal-body">
					<div class="row">
						<div v-if="loading" class="col-12 d-flex justify-content-center">
                            <img src="/assets/img/loading.svg">
                        </div>
						<div v-if="!loading" class="col-12">
							<div class="row">
								<app-info
									label="Fase em andamento"
									:value="tournament.current_stage.title"
								/>
								<div class="col-12 mb-2">
									<div class="card">
										<div class="card-header h6">
											Jogou as partidas mínimas
										</div>
										<div class="list-group list-group-flush">
											<li
												v-if="!tournament.current_stage.reached.length"
												class="list-group-item text-muted text-center"
											>
												:::	Ninguém :::
											</li>
											<li
												v-for="r in tournament.current_stage.reached"
												class="list-group-item"
											>
												{{ r.player.nickname }}
											</li>
										</div>
									</div>
								</div>
								<div class="col-12">
									<div class="card">
										<div class="card-header h6">
											Ainda não jogou as partidas mínimas
										</div>
										<div class="list-group list-group-flush">
											<li
												v-if="!tournament.current_stage.not_reached.length"
												class="list-group-item text-muted text-center"
											>
												:::	Ninguém :::
											</li>
											<li
												v-for="r in tournament.current_stage.not_reached"
												class="list-group-item d-flex justify-content-between"
											>
												<div>{{ r.player.nickname }}</div>
												<div
													class="badge badge-pill badge-warning"
													style="max-width: 100px;"
													data-toggle="popover"
													data-content="partidas jogadas/partidas mínimas"
												>
													{{ r.adherence }}
												</div>
											</li>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Info from '../InfoComponent';

	export default {
		components: {
			'app-info': Info,	
		},
		data() {
			return {
				modal: HTMLElement,
				title: '',
				loading: true,
				tournament: null,
			}
		},
		props: {
			defaultTitle: { type: String, default: 'Estatisticas' }
		},
		mounted() {
			this.title = this.defaultTitle;
		},
		methods: {
			open(id) {
				this.loading = true;
				this.modal = $(this.$refs.modal);
				this.title = 'Carregando...';
				this.modal.modal('show');

				axios.get(`/web/tournaments/${id}/current_stage`)
					.then(async res => {
						this.tournament = res.data;
						this.title = `${this.defaultTitle}: ${this.tournament.title}`;
						this.loading = false;

						await Help.delay(300);

						config.loadPopover();
					})
					.catch(err => {});
			},
			onClose() {
				this.modal.modal('hide');
			}
		}
	}
</script>