var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-object-group","title":"Categorias","breadcrumbs":[
            { href: '/', title: 'Painel' },
            { href: '', title: 'Categorias' },
        ]}}),_vm._v(" "),_c('app-datatable',{attrs:{"entity":"categories","allow-enable-multi":_vm.allows.enableMulti,"allow-disable-multi":_vm.allows.disableMulti,"allow-delete-multi":_vm.allows.deleteMulti,"options":{
            ajax: {
                url: '/web/categories'
            },
            serverSide: false,
            columns: _vm.columns
        },"actions":{
            challenges: {
                allow: true,
                url:  '/challenges?category_id=*'
            },
            create: {
                allow: _vm.allows.create,
                url: '/categories/create',
            },
            show: {
                allow: _vm.allows.show,
                url: '*',
                callback: _vm.onShow
            },
            edit: {
                allow: _vm.allows.edit,
                url: '/categories/*/edit',
            },
            delete: {
                allow: _vm.allows.delete,
                url: '*',
            },
        }}}),_vm._v(" "),_c('app-category-show',{ref:"categoryShow"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }