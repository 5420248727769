<template>
	<nav
		:class="[
			'col-12 d-flex d-md-none nav-mobile',
			{ 'inactive': active },
		]"
	>
		<a
			class="btn bt-arrow bt-menu-steps"
			href="javascript:"
			@click="onSelectMenu"
		>
			<i class="fa fa-chevron-down"></i>
		</a>
		<ul class="progress-buttons horizontal nav" role="tablist">
			<li
				v-for="s of STEPS"
				class="step"
				role="tab"
				:data-id="s"
				@click="onSelectStep"
			></li>
		</ul>
	</nav>
</template>

<script>
	export default {
		data() {
			return {
				STEPS: [0,1,2,3,4,6,11]
			};
		},
		props: {
			active: Boolean
		},
		methods: {
			onSelectMenu() {
				this.$emit('onSelectMenu');
			},
			onSelectStep(e) {
				this.$emit('onSelectStep', $(e.currentTarget).data('id'));
			}
		}
	}
</script>