<template>
	<div :class="containerClass">
		<div :class="[
			{ 'form-group': formGroup },
			{ 'required' : required }
		]">
			<label v-if="label" :for="name">
				{{ label }}
				<a
					v-if="help"
					:id="id"
					tabindex="0"
					class="btn btn-outline-info btn-circle btn-circle-xsm btn-help"
					data-toggle="popover"
					data-trigger="focus"
					role="button"
					:data-content="help.text"
				>
					<i class="fa fa-question"></i>
				</a>
			</label>
			<select
				ref="select"
				:id="id"
				:name="name"
				:class="((dataLiveSearch) ? 'selectpicker ' : '') + className"
				:data-live-search="dataLiveSearch"
				:data-live-search-normalize="dataLiveSearch"
				data-none-results-text="Nenhum resultado correspondente {0}"
				:required="required"
				:title="title"
				:disabled="disabled"
				:value="value"
				@input="updateValue"
			>
				<option v-if="empty" value="">{{ empty }}</option>
				<option
					v-for="option in options"
					:value="option.value"
					:selected="option.value == value"
				>
					{{ option.label }}
				</option>
			</select>
			<div v-if="error" class="invalid-feedback">
				{{ error }}
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			id: String,
			label: String,
			name: String,
			value: String | Number,
			required: Boolean,
			disabled: { type: Boolean, default: false },
			options: Array,
			title: String,
			dataLiveSearch: { type: Boolean, default: false },
			className: { type: String, default: 'custom-select form-control' },
			containerClass: { type: String, default: 'col-12 col-xl-4 col-md-6' },
			formGroup: { type: String, default: 'form-group' },
			empty: String,
			help: Object,
			error: String,
			callback: Function
		},
		watch: {
			options(newValue, oldValue) {
				if (this.dataLiveSearch) {
					this.refresh();
				}

				if (this.options.length == 1) {
					this.updateValue({ target: { value: this.options[0].value } });
				}
			}
		},
		methods: {
			updateValue(e) {
				this.$emit('input', e.target.value);

				if (this.callback) {
					this.callback(e.target.value);
				}
			},
			async refresh() {
				await Help.delay(100);
				
				$(this.$refs.select)
					.selectpicker('refresh')
					.on('change', this.updateValue)
					.selectpicker('val', this.value);
			}
		}
	}
</script>