import './bootstrap';
import './preloader';
import './flash';
import './utils/help';
import './config';
import './mobile-check';

import Vue from 'vue';
import Validator from './plugins/validator';
import AddScriptPlugin from './plugins/addScripts';
import DatatablesButtons from './plugins/datatablesButtons';
import VueMask from 'v-mask';
import ProfileType from './enums/ProfileType';

window.DEBUG = false;

import Header from './components/HeaderComponent';
import Side from './components/SideComponent';
import Footer from './components/FooterComponent';
import Cover from './components/CoverComponent';

/*	PAGES	*/
import Login from './components/LoginComponent';
import ForgotPassword from './components/passwords/ForgotPasswordComponent';
import ResetPassword from './components/passwords/ResetPasswordComponent';

import DashboardManager from './components/dashboards/DashboardManagerComponent';
import DashboardTeacher from './components/dashboards/DashboardTeacherComponent';
import Menu from './components/menus/MenuComponent';
import MenuCreate from './components/menus/MenuCreateComponent';
import MenuEdit from './components/menus/MenuEditComponent';
import Resource from './components/resources/ResourceComponent';
import ResourceCreate from './components/resources/ResourceCreateComponent';
import ResourceEdit from './components/resources/ResourceEditComponent';
import Profile from './components/profiles/ProfileComponent';
import ProfileCreate from './components/profiles/ProfileCreateComponent';
import ProfileEdit from './components/profiles/ProfileEditComponent';

import UserEdit from './components/users/UserEditComponent';
import Teacher from './components/teachers/TeacherComponent';
import Stage from './components/stages/StageComponent';
import StageEdit from './components/stages/StageEditComponent';
import StageCreate from './components/stages/StageCreateComponent';
import Manager from './components/managers/ManagerComponent';
import NotificationType from './components/notification_types/NotificationTypeComponent';
import
    NotificationTypeCreate
from './components/notification_types/NotificationTypeCreateComponent';
import
    NotificationTypeEdit
from './components/notification_types/NotificationTypeEditComponent';
import Notification from './components/notifications/NotificationComponent';
import NotificationEdit from './components/notifications/NotificationEditComponent';
import NotificationCreate from './components/notifications/NotificationCreateComponent';
import Category from './components/categories/CategoryComponent';
import CategoryEdit from './components/categories/CategoryEditComponent';
import CategoryCreate from './components/categories/CategoryCreateComponent';
import Challenge from './components/challenges/ChallengeComponent';
import ChallengeCreate from './components/challenges/ChallengeCreateComponent';
import ChallengeEdit from './components/challenges/ChallengeEditComponent';
import Player from './components/players/PlayerComponent';
import Tournament from './components/tournaments/TournamentComponent';
import TournamentCreate from './components/tournaments/TournamentCreateComponent';
import TournamentEdit from './components/tournaments/TournamentEditComponent';

Vue.use(Validator);
Vue.use(AddScriptPlugin);
Vue.use(DatatablesButtons);
Vue.use(VueMask);

PROFILE_CODE = ProfileType.getByName(PROFILE_CODE);

const app = new Vue({
    el: '#app',
    components: {
        'app-header': Header,
    	'app-side': Side,
    	'app-footer': Footer,
        'app-cover': Cover,

        'app-login': Login,
        'app-forgot-password': ForgotPassword,
        'app-reset-password': ResetPassword,

        'app-dashboard-manager': DashboardManager,
        'app-dashboard-teacher': DashboardTeacher,
        'app-menu': Menu,
        'app-menu-create': MenuCreate,
        'app-menu-edit': MenuEdit,
        'app-resource': Resource,
        'app-resource-create': ResourceCreate,
        'app-resource-edit': ResourceEdit,
        'app-profile': Profile,
        'app-profile-create': ProfileCreate,
    	'app-profile-edit': ProfileEdit,

        'app-user-edit': UserEdit,
        'app-teacher': Teacher,
        'app-stage': Stage,
        'app-stage-edit': StageEdit,
        'app-stage-create': StageCreate,
        'app-manager': Manager,
		'app-notification-type': NotificationType,
        'app-notification-type-create': NotificationTypeCreate,
        'app-notification-type-edit': NotificationTypeEdit,
        'app-notification': Notification,
        'app-notification-edit': NotificationEdit,
        'app-notification-create': NotificationCreate,
        'app-category': Category,
        'app-category-edit': CategoryEdit,
        'app-category-create': CategoryCreate,
        'app-challenge': Challenge,
        'app-challenge-create': ChallengeCreate,
        'app-challenge-edit': ChallengeEdit,
        'app-player': Player,
        'app-tournament': Tournament,
        'app-tournament-create': TournamentCreate,
        'app-tournament-edit': TournamentEdit,
    }
});
