<template>
	<div class="col-md-6">
		<div class="tile">
			<h3 class="tile-title">Distribuição</h3>
			<div v-if="loading" class="col-12 d-flex justify-content-center h-auto">
				<img src="/assets/img/loading.svg">
			</div>
			<div
				v-if="!loading"
				class="embed-responsive embed-responsive-16by9"
			>
				<canvas
					ref="chartContainer"
					class="embed-responsive-item"
				></canvas>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				loading: false,
				chart: null,
			};
		},
		mounted() {
			this.load();
		},
		methods: {
			load() {
				if (this.chart) this.chart.destroy();

				this.loading = true;

				axios.get(`/report/battles/ratings_distribution`)
					.then(async res => {
						this.loading = false;
						await Help.delay(300);
						this.loadChart(res.data);
					})
					.catch(err => {});
			},
			loadChart(data) {
				this.chart = new Chart(this.$refs.chartContainer, {
					type: 'pie',
					data: {
                        labels: data.labels,
                        datasets: [{
                            data: data.data,
                            backgroundColor: [
                                'rgba(90, 173, 0, 0.5)',
                                'rgba(255, 255, 0, 0.5)',
                                'rgba(255, 50, 0, 0.5)',
                                'rgba(150, 150, 150, 0.5)',
                            ],
                            borderColor: 'rgba(0, 0, 0, 0.2)',
                            borderWidth: 1,
                        }]
                    },
					options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem, data) {
									const label = data.labels[tooltipItem.index];
									const value = data
										.datasets[tooltipItem.datasetIndex]
										.data[tooltipItem.index];

                                    return `${label}: ${value}%`;
                                }
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false
                    }
				});
			}
		}
	}
</script>