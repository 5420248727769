<template>
    <div>
        <app-page-header
            icon="fa fa-list"
            :title="'Cadastrar Fase'"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '/stages', title: 'Fases' },
                { href: '', title: 'Cadastrar' },
            ]"
        />

        <div class="row">
            <div class="col-md-12">
                <div class="tile">
                    <div class="tile-body">
                        <form ref="form">
                            <div class="row">
                                <div class="col-12 col-sm-3">
                                    <div class="row">
                                        <app-chessboard
                                            :editable="true"
                                            containerClass="col-12"
                                            v-model="stage.fen"
                                        />
                                    </div>
                                </div>
                                <div class="col-12 col-sm-9">
                                    <div class="row">
                                        <app-select
                                            containerClass="col-12 col-xl-4 col-md-6"
                                            name="challenge_id"
                                            label="Desafio"
                                            title="Desafio"
                                            :required="true"
                                            :options="challenge"
                                            v-model="stage.challenge_id"
                                            :disabled="saving"
                                        />
                                        <app-input-multi
                                            containerClass="col-12 col-xl-4 col-md-6"
                                            name="title"
                                            placeHolder="Título"
                                            v-model="stage.title"
                                            :minLength="1"
                                            :maxLength="65"
                                            :required="true"
                                            :disabled="saving"
                                        />
                                        <app-input
                                            type="number"
                                            label="Movimentos mínimos"
                                            name="moves_min"
                                            :minLength="1"
                                            :maxLength="65"
                                            :required="true"
                                            v-model="stage.moves_min"
                                            :disabled="saving"
                                        />
                                        <app-input
                                            type="number"
                                            label="Movimentos máximos"
                                            name="moves_max"
                                            :minLength="1"
                                            :maxLength="65"
                                            :required="true"
                                            v-model="stage.moves_max"
                                            :disabled="saving"
                                        />
                                        <app-select
                                            name="opp_piece_type"
                                            label="Oponente"
                                            title="Oponente"
                                            :required="true"
                                            :options="opponent"
                                            v-model="stage.opp_piece_type"
                                            :disabled="saving"
                                        />
                                        <app-text-multi
                                            containerClass="col-12 col-xl-6"
                                            name="description"
                                            placeholder="Descrição"
                                            :minLength="1"
                                            :maxLength="255"
                                            :required="true"
                                            resize="none"
                                            v-model="stage.description"
                                            :disabled="saving"
                                        />
                                        <div class="col-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="objetivos">Objetivos</label>
                                                <div class="card">
                                                    <div class="card-body">
                                                        <app-checkbox
                                                            v-for="obj in objectives"
                                                            :key="obj.id"
                                                            containerClass="col-12"
                                                            placeholder="Objetivos"
                                                            name="objective_ids[]"
                                                            :label="obj.title"
                                                            :required="true"
                                                            :value="obj.id"
															v-model="stage.objective_ids"
                                                            :disabled="saving"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-12">
                                    <app-button 
                                        type="create" 
                                        :callback="onSubmit" 
                                        color="primary"
                                        :disabled="saving"
                                    />
                                    <app-link
                                        type="back"
                                        :url="backUrl"
                                        :disabled="saving"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Chessboard from '../ChessboardComponent'
    import Select from '../forms/SelectComponent';
    import Input from '../forms/InputComponent';
    import InputMulti from '../forms/InputMultiLanguageComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';
    import TextAreaMulti from '../forms/TextAreaMultiComponent';
    import Checkbox from '../forms/CheckboxComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
            'app-chessboard': Chessboard,
            'app-select': Select,
            'app-input': Input,
            'app-input-multi': InputMulti,
            'app-button': Button,
            'app-link': Link,
            'app-text-multi': TextAreaMulti,
            'app-checkbox': Checkbox
		},
        data() {
			return {
                challenge: [],
                opponent: [],
                objectives: [],
                stage: {
                    fen: '',
                    challenge_id: null,
                    moves_min: null,
                    moves_max: null,
                    objective_ids: [],
                    title: {
                        pt: null, en: null, es: null
                    },
                    description: {
                        pt: null, en: null, es: null
                    }
                },
                saving: false,
			}
		},
        props: {
			backUrl: String,
		},
        mounted() {
            Preloader.show();

            this.loadChallenges(() => {
                this.loadOpponent(() => {
                    this.loadObjecticves();
                });
            });
		},
        methods: {
            loadChallenges(callback) {
                axios.get(`/web/challenges/list?toTags=true`)
                .then(res => {
                    this.challenge = res.data;
                    callback();
                })
                .catch(err => {});
            },
            loadOpponent(callback) {
                axios.get(`/web/stages/list_opponent_piece_types?toTags=true`)
                .then(res => {
                    this.opponent = res.data;
                    callback();
                })
                .catch(err => {});
            },
            loadObjecticves() {
                axios.get(`/web/stages/list_objectives?toTags=true`)
                .then(res => {
					this.objectives = res.data;
                    Preloader.hide();
				})
				.catch(err => {});
            },
            onSubmit() {
                this.saving = true;
                let validate = this.validateForm(this.$refs.form);
                
                if (validate != '') {
                    Flash('error', validate);
                    this.saving = false;
                } else {
                    if (this.stage.fen == '') {
                        Flash('error', 'Preencha o desafio ao lado!');
                        this.saving = false;
                        return;
                    }
                    

                    axios.post(`/web/stages`, this.stage)
                        .then(res => {
                            Swal.fire({
                                title: 'Atualizado com sucesso!',
                                type: 'success'
                            })
                            .then(result => {
                                window.location.href = '/stages';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar atualizar registro!');
                            this.saving = false;
                        });
                }
            }
        }
    }
</script>