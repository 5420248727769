<template>
    <div>
        <app-page-header
            icon="fa fa-bell"
            title="Notificações"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Notificações' },
            ]"
        />

        <app-datatable
            entity="notifications"
            :allow-enable-multi="allows.enableMulti"
            :allow-disable-multi="allows.disableMulti"
            :allow-delete-multi="allows.deleteMulti"
            :statusFilterIndex="5"
            :options="{
                ajax: {
                    url: '/web/notifications'
                },
                serverSide: false,
                columns: this.columns
            }"
            :actions="{
				create: {
                    allow: this.allows.create,
                    url: '/notifications/create',
                },
                show: {
                    allow: this.allows.show,
                    url: '*',
                    callback: onShow
                },
                edit: {
                    allow: this.allows.edit,
                    url: '/notifications/*/edit',
                },
            }"
        />

        <app-notification-show ref="notificationShow" />
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import DataTable from '../DataTableComponent';
    import NotificationShow from './NotificationShowComponent';
    import Link from '../forms/LinkComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
            'app-datatable': DataTable,
            'app-notification-show': NotificationShow,
            'app-link': Link,
		},
        props: {
			allows: Object,
			columns: Array,
			status: Array,
		},
        methods: {
			onShow(id) {
				this.$refs.notificationShow.open(id);
			}
		}
    }
</script>