<template>
	<div class="tab-pane fade max-h" id="step3" role="tabpanel">
		<h3 class="text-center">Quais salas participarão?</h3>
		<form name="formSearch" class="row" onsubmit="return false">
			<div class="col-12 col-md-8">
				<app-input
					containerClass=""
					:maxLength="30"
					placeholder=" Busca"
					v-model="filter.search"
					@input="loadClassrooms"
				/>
			</div>
			<div class="col-12 col-md-4">
				<app-select	
					containerClass=""
					className="form-control"
					empty="Todos os anos"
					:options="years"
					v-model="filter.year"
					@input="loadClassrooms"
				/>
			</div>
			<div class="col-12">
				<div class="animated-checkbox">
					<label>
						<input
							type="checkbox"
							@change="onSelectAll"
						>
						<span class="label-text">Selecionar todas</span>
					</label>
				</div>
			</div>
		</form>
		<div class="row container-classrooms">
			<div
				v-if="loading"
				class="text-center w-100"
			>
				<img src="/assets/img/loading.svg">
			</div>
			<div 
				v-if="!loading && classrooms.length == 0"
				class="col-12 text-muted text-center"
			>
				Nenhuma sala encontrada!
			</div>
			<app-checkbox
				v-for="classroom of classrooms"
				:key="classroom.id"
				containerClass="col-12 col-md-6"
				:value="classroom.id"
				:label="classroom.title"
				v-model="data"
				@input="updateValue"
			/>
		</div>
		<app-arrows
			:disabled="!btNextEnabled"
			@click="onClickArrow"
		/>
	</div>
</template>

<script>
	import Input from '../../forms/InputComponent';
	import Select from '../../forms/SelectComponent';
	import Checkbox from '../../forms/CheckboxComponent';
	import Arrows from './ArrowsComponent';

	export default {
		components: {
			'app-input': Input,
			'app-select': Select,
			'app-checkbox': Checkbox,
			'app-arrows': Arrows,
		},
		model: {
			prop: 'modelValue',
			event: 'input',
		},
		data() {
			return {
				loading: false,
				btNextEnabled: false,
				years: [],
				classrooms: [],
				filter: {
					search: '',
					year: null,
				},
				data: []
			};
		},
		props: {
			value: null,
			modelValue: { type: Array, required: true }
		},
		mounted() {
			this.loadYears();
			this.loadClassrooms();
		},
		methods: {
			loadYears() {
				axios.get('/web/years/list?toTags=true')
					.then(res => {
						this.years = res.data;
					})
					.catch(err => {});
			},
			loadClassrooms() {
				this.loading = true;
				this.classrooms = [];
				this.$emit('input', []);

				axios.get('/web/classrooms/list?fields=mn_id,title', { params: this.filter })
					.then(res => {
						this.loading = false;
						this.classrooms = res.data;
					})
					.catch(err => {});
			},
			updateValue(value) {
				this.$emit('input', value);
				this.checkNextButton();
			},
			onClickArrow(direction) {
				this.$emit('click', direction);
			},
			onSelectAll(e) {
				$('.container-classrooms input[type="checkbox"]')
					.prop('checked', e.target.checked);

				if (e.target.checked) {
					this.$emit('input', this.classrooms.map(c => c.id));
				} else {
					this.$emit('input', []);
				}

				this.checkNextButton();
			},
			async checkNextButton() {
				await Help.delay(300);
				
				this.btNextEnabled = this.modelValue.length > 0;
			}
		}
	}
</script>