<template>
    <div
		ref="modal"
		class="modal fade"
		tabindex="-1"
		role="dialog"
	>
		<div
			class="modal-dialog modal-dialog-centered modal-lg"
			role="document"
		>
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						<i class="fa fa-object-group"></i>
						{{ title }}
					</h5>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						@click="onClose"
					>
			         	<span aria-hidden="true">&times;</span>
			        </button>
				</div>
				<div class="modal-body">
                    <div class="row">
						<div v-if="loading" class="col-12 d-flex justify-content-center">
							<img src="/assets/img/loading.svg">
						</div>
						<div v-if="!loading" class="col-12">
							<div class="row">
                                <div class="col-4 col-xl-3 col-md-6">
                                    <img class="rounded img-thumbnail" :src="category.thumb" >
                                </div>
                                <app-info-multi
                                    label="Título"
                                    :value="category.title"
                                />
                                <app-info
                                    containerClass="col-12 col-xl-2 mt-2"
                                    label="Status"
                                    :value="category.active_text"
                                />
                            </div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Info from '../InfoComponent';
	import InfoMulti from '../InfoMultiComponent';

    export default {
		components: {
			'app-info': Info,
			'app-info-multi': InfoMulti,
		},
        data() {
			return {
				modal: HTMLElement,
				title: '',
				loading: true,
				category: null,
			}
		},
		props: {
			defaultTitle: { type: String, default: 'Categoria' }
		},
        methods: {
            open(id) {       
                this.loading = true;
				this.modal = $(this.$refs.modal);
				this.title = 'Carregando...';
				this.modal.modal('show');

                axios.get(`/web/categories/${id}`)
					.then(res => {
						this.category = res.data;
                        this.loading = false;
						this.title = `${this.defaultTitle}: ${this.category.id}`;
					})
					.catch(err => {});
            },
            onClose() {
				this.modal.modal('hide');
			}
        }
    }
</script>