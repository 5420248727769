<template>
	<div :class="containerClass">
		<div :class="[subClass, 'form-group']">
			<label v-if="label" :for="name">{{ label }}</label>
			<div class="toggle lg">
				<input type="hidden" :name="name" value="0">
				<label>
					<input
						type="checkbox"
						:placeholder="placeholder"
						:name="name"
						:value="value"
						:disabled="disabled"
						:checked="isChecked"
						@input="updateValue"
					>
					<span class="button-indecator"></span>
				</label>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		model: {
            prop: 'modelValue',
            event: 'input'
        },
		data() {
			return {
				type: 'toggle'
			}
		},
		props: {
			placeholder: String,
			label: String,
			name: String,
			value: null,
			containerClass: { type: String, default: 'col-12 col-xl-3 col-md-6' },
			subClass: { type: String, default: 'col-12 col-xl-3 col-md-6' },
			label: String,
			disabled: { type: Boolean, default: false },
			modelValue: { required: true, type: Array | String | Number }
		},
		computed: {
            isChecked() {
				if (this.type == 'checkbox') {
                	return this.modelValue.includes(this.value);
				}
				
				return this.modelValue;
            }
        },
		mounted() {
			this.type = (this.modelValue instanceof Array) ? 'checkbox' : 'toggle';
		},
		methods: {
			updateValue(e) {
				if (this.type == 'checkbox') {
					let newValue = e.target.value;
					
					let updatedValue = [...this.modelValue];

					if (e.target.checked) {
						updatedValue.push(newValue);
					} else {
						updatedValue = updatedValue.filter(value => value != newValue);
					}
					
					this.$emit('input', updatedValue);
				} else {
					this.$emit('input', e.target.checked);
				}
			}
		}
	}
</script>