<template>
	<div :class="containerClass">
		<div class="image-container justify-content-center justify-content-sm-start">
			<img
				class="thumb img-thumbnail img-fluid rounded h-auto"
				title="Editar imagem"
				:src="tempUrl"
				@click="openEdit"
			>
			<app-button
				:withTitle="false"
				icon="trash"
				color="danger"
				customClass="btn-image-trash"
				:outline="false"
				title="Remover imagem"
				:callback="deleteImage"
			/>
		</div>
		<div
			ref="modalImageEdit"
			class="modal fade"
			tabindex="-1"
			role="dialog"
			aria-hidden="true"
		>
			<div
				class="modal-dialog modal-dialog-centered"
				role="document"
			>
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">
							<i class="fa fa-image"></i>
							Editar Imagem
						</h5>
						<button
							type="button"
							class="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<app-file-input
							ref="fileInput"
							containerClass="col-12"
							accept="image/png,image/jpeg,image/jpg,image/gif,image/bmp"
							:callback="onLoad"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Button from './forms/ButtonComponent';
	import FileInput from './forms/FileInputComponent';

	export default {
		components: {
			'app-button': Button,
			'app-file-input': FileInput
		},
		data() {
			return {
				fileInput: null,
				tempUrl: '',
				modal: null,
			}
		},
		props: {
			url: String,
			default: { type: String, required: true },
			containerClass: { type: String, default: 'col-12' }
		},
		mounted() {
			this.fileInput = this.$refs.fileInput;
			this.modal = $(this.$refs.modalImageEdit);
			this.tempUrl = this.url;
		},
		methods: {
			openEdit() {
				this.modal.modal('show');
			},
			deleteImage() {
				Swal.fire({
					type: 'warning',
					title: 'Atenção!',
					text: 'Quer mesmo excluir a imagem?',
					showCancelButton: true,
					cancelButtonText: 'Não',
					confirmButtonText: 'Sim'
				})
				.then(res => {
					if (res.value) {
						this.$emit('onDelete');
					}
				})
			},
			onLoad(image) {
				this.modal.modal('hide');

				let reader = new FileReader();
				reader.onload = (e) => {
					this.tempUrl = e.currentTarget.result;
					this.$emit('onLoaded', this.tempUrl);
					this.fileInput.reset();
				}
				reader.readAsDataURL(image);
			},
			clear() {
				this.tempUrl = this.default;
			}
		}
	}
</script>