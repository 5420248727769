<template>
    <div>
        <app-page-header
            icon="fa fa-user"
            title="Professores"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Professores' },
            ]"
        />

        <app-datatable
            entity="teachers"
            :allow-enable-multi="allows.enableMulti"
            :allow-disable-multi="allows.disableMulti"
            :allow-delete-multi="allows.deleteMulti"
            :withStatusFilter="true"
            :options="{
                ajax: {
                    url: '/web/teachers'
                },
                columns: this.columns
            }"
            :actions="{
                show: {
                    allow: this.allows.show,
                    url: '*',
                    callback: onShow
                },
            }"
        />

        <app-teacher-show ref="teacherShow" />
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
	import DataTable from '../DataTableComponent';
    import TeacherShow from './TeacherShowComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
			'app-datatable': DataTable,
            'app-teacher-show': TeacherShow
		},
		props: {
			allows: Object,
			columns: Array,
		},
		methods: {
			onShow(id) {
                this.$refs.teacherShow.open(id);
			}
		}
    }
</script>