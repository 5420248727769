export default {
    getButton(type, icon, title, href, rowId = null, color = 'info', callback = null) {
        return `
            <a
                class="btn btn-outline-${color} ${icon == 'file-pdf-o' ? 'colorbox' : ''} btn-circle btn-circle-sm btn-table"
                data-content="${title}"
                href="${href}"
                data-toggle="popover"
                data-type="${type}"
                ${(rowId) ? ` data-id="${rowId}"` : ''}
                ${(callback) ? ` data-function="${callback}"` : ''}
            >
                <i class="fa fa-${icon}"></i>
            </a>
        `;
    },
    install(Vue, options) {
        Vue.prototype.formatDatatablesButtons = (data) => {
            let actions = {};

            if (data.hasOwnProperty('stages') && data.stages.allow) {
                actions.stages = this.getButton(
                    'stages',
                    'list',
                    'Fases',
                    data.stages.url,
                    null
                );
            }

            if (data.hasOwnProperty('performance') && data.performance.allow) {
                actions.performance = this.getButton(
                    'performance',
                    'tachometer',
                    data.performance.title ?? 'Desempenho',
                    'javascript:',
                    '*'
                );
            }

            if (data.hasOwnProperty('ranking') && data.ranking.allow) {
                actions.ranking = this.getButton(
                    'ranking',
                    'list-ol',
                    data.ranking.title ?? 'Ranking',
                    'javascript:',
                    '*'
                );
            }

            if (data.hasOwnProperty('statistics') && data.statistics.allow) {
                actions.statistics = this.getButton(
                    'statistics',
                    'signal',
                    data.statistics.title ?? 'Estatística',
                    'javascript:',
                    '*'
                );
            }

            if (data.hasOwnProperty('show') && data.show.allow) {
                actions.show = this.getButton(
                    'show',
                    'eye',
                    data.show.title ?? 'Visualizar',
                    'javascript:',
                    '*'
                );
            }

            if (data.hasOwnProperty('edit') && data.edit.allow) {
                actions.edit = this.getButton(
                    'edit',
                    'pencil',
                    'Editar',
                    data.edit.url,
                    null,
                    'warning'
                );
            }

            if (data.hasOwnProperty('delete') && data.delete.allow) {
                actions.delete = this.getButton(
                    'delete',
                    'trash',
                    'Deletar',
                    'javascript:',
                    '*',
                    'danger',
                    'onDelete'
                );
            }

            return actions;
        };
    }
}