<template>
    <div v-if="challenge">
        <app-page-header
            icon="fa fa-gamepad"
            :title="'Editar Desafio: ' + challenge.id"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '/challenges', title: 'Desafios' },
                { href: '', title: 'Editar' }
            ]"
        />

        <div class="row">
            <div class="col-12">
                <div class="tile">
                    <div class="tile-body">
                        <form ref="form">
                            <div class="row">
								<div class="col-12 col-xl-2 col-lg-3">
                                    <div class="row">
                                        <app-image
                                            ref="imageManager"
                                            :url="challenge.thumb"
                                            :default="DEFAULT_IMAGE"
                                            @onLoaded="saveImage"
                                            @onDelete="deleteImage"
                                        />
                                    </div>
                                </div>
                                <app-select
									containerClass="col-12 col-xl-4 col-md-6"
                                    name="category_id"
                                    label="Categoria"
                                    title="Categoria"
                                    :required="true"
                                    :options="categories"
                                    v-model="challenge.category_id"
									:disabled="saving"
                                />
                                <app-input-multi
									containerClass="col-12 col-xl-4 col-md-6"
                                    name="title"
                                    placeHolder="Título"
                                    :minLength="1"
                                    :maxLength="65"
                                    :required="true"
									:disabled="saving"
									v-model="challenge.title"
                                />
								<app-toggle
									label="Status"
									:disabled="saving"
									v-model="challenge.active"
								/>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-12">
                                    <app-button 
                                        type="update" 
                                        :callback="onSubmit" 
                                        color="primary"
										:disabled="saving"
                                    />
                                    <app-link
										type="back"
										:url="backUrl"
										:disabled="saving"
									/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Select from '../forms/SelectComponent';
    import InputMulti from '../forms/InputMultiLanguageComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';
	import Toggle from '../forms/ToggleComponent';
	import ImageManager from '../ImageManagerComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-select': Select,
            'app-input-multi': InputMulti,
            'app-button': Button,
            'app-link': Link,
			'app-toggle': Toggle,
			'app-image': ImageManager,
        },
        data() {
			return {
				saving: false,
				challenge: null,
                categories: [],
				imageManager: null,
				DEFAULT_IMAGE: '/assets/img/default_thumbs/category.png'
			}
		},
        props: {
			id: String,
			backUrl: String,
		},
        mounted() {
			Preloader.show();
            this.loadCategories(() => {
				this.load(this.id);
			});
        },
        methods: {
			loadCategories(callback) {
                axios.get(`/web/categories/list?toTags=true`)
					.then(res => {
						this.categories = res.data;
						callback();
					})
					.catch(err => {});
            },
			load(id) {
				axios.get(`/web/challenges/${id}`)
                	.then(async res => {
                        this.challenge = res.data;

						await Help.delay(300);

                        this.imageManager = this.$refs.imageManager;
						Preloader.hide();
					})
					.catch(err => {});
			},
			openEditImage() {
                this.$refs.imageSelect.open();
            },
			imageLoaded(image) {
                if (image) {
                    let reader = new FileReader();
                    reader.onload = (e) => {
                       this.tempImage = e.currentTarget.result;
                    }
                    reader.readAsDataURL(image);
                } else {
                    this.tempImage = this.DEFAULT_IMAGE;
                }
            },
			saveImage(image) {
                Preloader.show();

                axios.patch(`/web/challenges/${this.id}/image`, { image })
                    .then(res => {
                        Flash('success', 'Image salva com sucesso!');
                        Preloader.hide();
                    })
                    .catch(err => {
                        Flash('error', 'Erro ao tentar salvar imagem!');
                        Preloader.hide();
                    });
            },
            deleteImage() {
                Preloader.show();

                axios.delete(`/web/challenges/${this.id}/image`)
                    .then(res => {
                        Flash('success', 'Image removida com sucesso!');
                        this.imageManager.clear();
                        Preloader.hide();
                    })
                    .catch(err => {
                        Flash('error', 'Erro ao tentar remover imagem!');
                        Preloader.hide();
                    });
            },
            onSubmit() {
                this.saving = true;
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
					this.saving = false;
                } else {
                    axios.put(`/web/challenges/${this.challenge.id}`, this.challenge)
                        .then(res => {
                            Swal.fire({
                                title: 'Desafio atualizado com sucesso!',
                                type: 'success'
                            })
                            .then(result => {
                                window.location.href = '/challenges';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar atualizar desafio!');
							this.saving = false;
                        });
                }
            }
		}
    }
</script>