<template>
	<div
		class="tab-pane fade max-w-mobile"
		id="step6"
		role="tabpanel"
	>
		<h3 class="text-center">Critérios de desempate</h3>
		<p class="text-center">
			Os critérios de desempate são aplicados quando o número de
			classificados é maior que o definido para cada fase do torneio.
		</p>
		<p>
			<h6>Vitórias</h6>
			<span>
				Leva-se em conta os participantes que mais
				vencerem.
			</span>
			<h6>Derrotas</h6>
			<span>
				São escolhidos os participantes que tem o menor número 
				de derrotas.
			</span>
			<h6>Abandonos</h6>
			<span>
				Os participantes que menos abandonarem partidas são 
				selecionados.
			</span>
			<h6>Número de partidas</h6>
			<span>
				São considerados os participantes que acumularem o maior número
				de partidas.
			</span>
		</p>
		<app-arrows @click="onClickArrow" />
	</div>
</template>

<script>
	import Arrows from './ArrowsComponent';

	export default {
		components: {
			'app-arrows': Arrows,
		},
		methods: {
			onClickArrow(direction) {
				this.$emit('click', direction);
			}
		}
	}
</script>