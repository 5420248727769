<template>
    <div>
        <app-page-header
            icon="fa fa-bell"
            title="Cadastrar Notificação"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '/notifications', title: 'Notificações' },
                { href: '', title: 'Cadastrar' },
            ]"
        />

        <div class="row">
            <div class="col-md-12">
                <div class="tile">
                    <div class="tile-body">
                        <form ref="form">
                            <div class="row">
                                <app-select
                                    label="Tipo"
                                    title="Tipo"
                                    :required="true"
                                    :options="notifications_type"
                                    v-model="notification.notification_type_id"
                                    :disabled="saving"
                                />
                                <app-input
                                    label="Link"
                                    :minLength="0"
                                    :maxLength="120"
                                    v-model="notification.link"
                                    :disabled="saving"
                                />
                                <app-input
                                    label="Url Imagem"
                                    :minLength="2"
                                    :maxLength="120"
                                    v-model="notification.image"
                                    :disabled="saving"
                                />
                                <app-input-multi
									name="title"
                                    placeHolder="Título"
                                    :minLength="1"
                                    :maxLength="150"
									:required="true"
									v-model="notification.title"
                                    :disabled="saving"
                                />
                                <app-input-multi
									name="message"
                                    containerClass="col-12 col-xl-6"
                                    placeHolder="Mensagem"
                                    :minLength="1"
                                    :maxLength="255"
									:required="true"
									v-model="notification.message"
                                    :disabled="saving"
                                />
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-12">
                                    <app-button 
                                        type="create" 
                                        :callback="onSubmit" 
                                        color="primary"
                                        :disabled="saving"
                                    />
                                    <app-link
                                        type="back"
                                        :url="backUrl"
                                        :disabled="saving"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Select from '../forms/SelectComponent';
    import Input from '../forms/InputComponent';
    import InputMulti from '../forms/InputMultiLanguageComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
            'app-select': Select,
            'app-input': Input,
            'app-input-multi': InputMulti,
            'app-button': Button,
            'app-link': Link,
		},
        data() {
			return {
				notification: {
					notification_type_id: null,
					link: null,
					image: null,
					title: { pt: null, en: null, es: null },
					message: { pt: null, en: null, es: null },
				},
                notifications_type: [],
                saving: false,
			}
		},
        props: {
			backUrl: String,
		},
        mounted() {
            this.loadNotificationsType();
		},
        methods: {
            loadNotificationsType() {
                axios.get('/web/notification_types/list?toTags=true')
					.then(res => {
						this.notifications_type = res.data;
					})
					.catch(err => {});
            },
            onSubmit() {
                this.saving = true;
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                    this.saving = false;
                } else {
                    axios.post(`/web/notifications`, this.notification)
                        .then(res => {
                            Swal.fire({
								title: 'Notificação criada com sucesso!',
								type: 'success'
							})
							.then(result => {
								window.location.href = '/notifications';
							});
                        })
					    .catch(err => {
                            Flash('error', 'Erro ao tentar gravar registro!');
                            this.saving = false;
                        });
                }
            }
		}
    }
</script>