<template>
	<div
		ref="modal"
		class="modal fade"
		tabindex="-1"
		role="dialog"
	>
		<div
			class="modal-dialog modal-dialog-centered modal-lg"
			role="document"
		>
			<div class="modal-content">
				<div v-if="!loading" class="modal-header border-0">
					<img 
						:src="manager.user.image"
						class="thumb thumb-sm img-thumbnail rounded img-fluid thumb-show shadow"
					>
					<h5 class="modal-title text-center w-100">
						<i class="fa fa-user"></i>
						{{ title }}
					</h5>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						@click="onClose"
					>
			         	<span aria-hidden="true">&times;</span>
			        </button>
				</div>
				<div class="modal-body">
                    <div class="row content">
						<div v-if="loading" class="col-12 text-center">
							<img src="/assets/img/loading.svg">
						</div>
						<div v-if="!loading" class="col-12">
							<div class="row">
								<app-info
									label="ID"
									:value="manager.id"
								/>
								<app-info
									label="Nome"
									:value="manager.name"
								/>
								<app-info
									label="Usuário"
									:value="manager.user.login"
								/>
								<app-info
									label="Perfil"
									:value="manager.user.profile.title"
								/>
								<app-info
									label="Cadastrado em"
									:value="new Date(manager.created_at).toLocaleString()"
								/>
								<app-info
									label="Status"
									:value="manager.active_text"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Info from '../InfoComponent';

	export default {
		components: {
			'app-info': Info
		},
		data() {
			return {
				modal: HTMLElement,
				title: '',
				loading: true,
				manager: null
			}
		},
		props: {
			defaultTitle: { type: String, default: 'Gerente' }
		},
		mounted() {
			this.title = this.defaultTitle;
		},
		methods: {
			open(id) {
				this.loading = true;
				this.modal = $(this.$refs.modal);
				this.title = 'Carregando...';
				this.modal.modal('show');

				axios.get(`/web/managers/${id}`)
					.then(res => {
						this.manager = res.data;
						this.loading = false;
						this.title = `${this.defaultTitle}: ${this.manager.id}`;
					})
					.catch(err => {});
			},
			onClose() {
				this.modal.modal('hide');
			}
		}
	}
</script>