<template>
	<div class="tab-pane fade" id="step0" role="tabpanel">
		<h3 class="text-center">Introdução</h3>
		<p class="text-center">
			Este guia tem como objetivo auxiliá-lo na criação de
			torneio para seus estudantes.
		</p>
		<app-arrows
			:prevHide="true"
			@click="onClickArrow"
		/>
	</div>
</template>

<script>
	import Arrows from './ArrowsComponent';

	export default {
		components: {
			'app-arrows': Arrows,
		},
		methods: {
			onClickArrow(direction) {
				this.$emit('click', direction);
			}
		}
	}
</script>