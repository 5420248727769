<template>
    <div ref="modal" class="modal fade" tabindex="-1" role="dialog">
		<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
			<div class="modal-content">
				<div v-if="!loading" class="modal-header border-0">
					<img 
						:src="teacher.user.image"
						class="thumb thumb-sm img-thumbnail rounded img-fluid thumb-show shadow"
					>
					<h5 class="modal-title text-center w-100">
						<i class="fa fa-user"></i>
						{{ title }}
					</h5>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						@click="onClose"
					>
			         	<span aria-hidden="true">&times;</span>
			        </button>
				</div>
				<div class="modal-body">
                    <div class="row content">
                        <div v-if="loading" class="col-12 text-center">
							<img src="/assets/img/loading.svg">
						</div>
                        <div v-if="!loading" class="col-12">
                            <div class="row">
                                <app-info
                                    label="ID"
                                    :value="teacher.id"
                                />
                                <app-info
                                    label="Nome"
                                    :value="teacher.name"
                                />
                                <app-info
                                    label="Usuário"
                                    :value="teacher.user.login"
                                />
                                <app-info
                                    label="Cadastrado em"
                                    :value="new Date(teacher.created_at).toLocaleString()"
                                />
                                <app-info
                                    label="Status"
                                    :value="teacher.active_text"
                                />
                            </div>
                            <h5>Escola(s)</h5>
                            <hr>
                            <div class="row">
                                <div
                                    class="col-12 col-xl-4 my-1"
                                    v-for="school in teacher.schools"
                                >
                                    <div class="card">
                                        <div
                                            class="card-body p-1 text-truncate"
                                            :title="school.name"
                                        >
                                            {{ school.name }}
                                        </div>
                                    </div>
                                </div>
                            </div><br>
                            <h5>Sala(s)</h5>
                            <hr>
                            <div class="row">
                                <div 
                                    class="col-12 col-xl-4 my-1" 
                                    v-for="classroom in teacher.classrooms"
                                >
                                    <div class="card">
                                        <div 
                                            class="card-body p-1 text-truncate" 
                                            :title="classroom.title"
                                        >
                                            {{ classroom.title }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    import Info from '../InfoComponent';

    export default {
        components: {
            'app-info': Info
        },
        data() {
            return {
                modal: HTMLElement,
                title: '',
                loading: true,
                teacher: null
            }
        },
        props: {
            defaultTitle: { type: String, default: 'Professor' }
        },
        mounted() {
            this.title = this.defaultTitle;
        },
        methods: {
            open(id) {               
                this.loading = true;
                this.modal = $(this.$refs.modal);
                this.title = 'Carregando...';
                this.modal.modal('show');

                axios.get(`/web/teachers/${id}`)
                    .then(res => {			
                        this.loading = false;
                        this.teacher = res.data;
                        this.title = `${this.defaultTitle}`;
                    })
                    .catch(err => {});
            },
            onClose() {
                this.modal.modal('hide');
            }
        }
    }
</script>