<template>
	<div :class="containerClass">
		<p><strong>{{ label }}</strong></p>
		<p v-html="value"></p>
	</div>
</template>

<script>
	export default {
		props: {
			containerClass: { type: String, default: "col-12 col-xl-3 col-md-6 mt-2" },
			label: String,
			value: '',
		}
	}
</script>