<template>
	<div :class="containerClass">
		<nav ref="pagination"></nav>
	</div>
</template>

<script>
	export default {
		model: {
			prop: 'modelValue',
			props: 'input'
		},
		props: {
			containerClass: { type: String, default: 'col-12' },
			value: { type: Number }
		},
		methods: {
			load(lastPage) {
				$(this.$refs.pagination).bootstrapPaginator({
					alignment: 'center',
					size: 'normal',
					tooltipTitles: () => '',
					numberOfPages: 5,
					currentPage: this.value,
					totalPages: lastPage,
					shouldShowPage: function (type, page, current) {
						switch (type) {
							case 'first':
							case 'last': return false;
							default: return true;
						}
					},
					itemTexts: function (type, page, current) {
						switch (type) {
							case 'prev': return 'Anterior';
							case 'next': return 'Próxima';
							case 'page': return page;
						}
					},
					onPageClicked: (e, originalEvent, type, page) => {
						this.$emit('input', page);
					}
				});
			}
		}
	}
</script>