<template>
	<nav
		:class="[
			'p-0 col-md-4 d-none d-md-block nav-browse',
			{ 'active' : !active },
		]"
	>
		<div class="d-flex header-mobile">
			<a
				class="btn bt-arrow bt-menu-steps"
				href="javascript:"
				@click="onSelectMenu"
			>
				<i class="fa fa-chevron-down"></i>
			</a>
			<h3 class="font-weight-bold w-100">Torneio</h3>
		</div>
		<div class="p-4">
			<h3 class="font-weight-bold">Torneio</h3>
			<p>
				Crie um torneio e engaje os seus estudantes, em uma divertida
				e desafiadora aventura cheia de raciocínio, lógica e estratégia.
			</p>
			<ul class="progress-buttons" role="tablist">
				<li
					v-for="s in STEPS"
					class="step"
					role="tab"
					:data-id="s.id"
					@click="onSelectStep"
				>
					<span v-if="s.id != 4">{{ s.title }}</span>
					<div v-if="s.id == 4" class="unclickable">Fases</div>
					<ul v-if="s.id == 4" class="sub-steps">
						<li
							v-for="stage of stages"
							class="text-muted"
						>
							{{ stage.title }}
						</li>
					</ul>
				</li>
			</ul>
		</div>
	</nav>
</template>

<script>
	export default {
		data() {
			return {
				STEPS: [
					{ id: 0, title: 'Introdução' },
					{ id: 1, title: 'Tipo de torneio' },
					{ id: 2, title: 'Nome do torneio' },
					{ id: 3, title: 'Salas participantes' },
					{ id: 4, title: '' },
					{ id: 6, title: 'Critérios de desempate' },
					{ id: 11, title: 'Finalização' },
				],
			};
		},
		props: {
			active: Boolean,
			stages: []
		},
		methods: {
			onSelectMenu() {
				this.$emit('onSelectMenu');
			},
			onSelectStep(e) {
				this.$emit('onSelectStep', $(e.currentTarget).data('id'));
			},
		}
	}
</script>