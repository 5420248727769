<template>
    <div v-if="profile">
        <app-page-header
			icon="fa fa-cog"
			:title="'Editar Perfil: ' + profile.id"
			:breadcrumbs="[
				{ href: '/', title: 'Painel' },
				{ href: '/profiles', title: 'Perfis' },
				{ href: '', title: 'Editar' },
			]"
		/>

        <div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
                        <form ref="form">
                            <div class="row">
								<app-input
									label="Título"
									:minLength="2"
									:maxLength="50"
									:required="true"
									v-model="profile.title"
								/>
								<app-input
									label="Código"
									name="code"
									:minLength="2"
									:maxLength="50"
									:required="true"
									v-model="profile.code"
								/>
								<app-toggle
									label="Status"
									v-model="profile.active"
								/>
							</div>
                            <h5 class="h5">Permissões</h5>
							<hr />
                            <div class="row">
								<div id="accordion" class="accordion col-12">
									<div class="card">
										<div class="card-header" id="headingMenu">
											<button
												type="button"
												class="btn btn-outline-secondary"
												data-toggle="collapse"
												data-target="#menu"
												aria-expanded="true"
												aria-controls="menu"
											>
												Menu
											</button>
										</div>
										<div 
											id="menu"
											class="collapse show"
											aria-labelledby="headingMenu"
											data-parent="#accordion"
										>
											<div class="card-body" v-if="menus">
												<app-checkbox-group
													name="menus"
													:value="menus"
													v-model="profile.menu_ids"
												/>
											</div>
										</div>
									</div>
									<div class="card">
										<div class="card-header" id="headingResources">
											<button
												type="button"
												class="btn btn-outline-secondary"
												data-toggle="collapse"
												data-target="#resources"
												aria-expanded="false"
												aria-controls="resources"
											>
												Recursos
											</button>
										</div>
										<div
											id="resources"
											class="collapse"
											aria-labelledby="headingResources"
											data-parent="#accordion"
										>
											<div class="card-body" v-if="resources">
												<app-checkbox-group
													name="resources"
													:value="resources"
													v-model="profile.resource_ids"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<hr />
							<div class="row">
								<div class="col-12">
									<app-button
										type="update"
										:callback="onSubmit"
									/>
									<app-link
										type="back"
										:url="backUrl"
									/>
								</div>
							</div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
	import Input from '../forms/InputComponent';
	import Toggle from '../forms/ToggleComponent';
	import CheckboxGroup from '../forms/CheckboxGroupComponent';
	import Button from '../forms/ButtonComponent';
	import Link from '../forms/LinkComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
			'app-input': Input,
			'app-toggle': Toggle,
			'app-checkbox-group': CheckboxGroup,
			'app-button': Button,
			'app-link': Link
		},
		data() {
			return {
				profile: null,
				menus: [],
				resources: [],
				saving: false,
			}
		},
		props: {
			id: String,
			backUrl: String,
		},
        mounted() {
			Preloader.show();
			this.loadMenus(() => {
				this.loadResources(() => {
					this.load(this.id);
				});
			});
		},
		methods: {
			loadMenus(callback) {
				axios.get('/web/menus/grouped')
					.then(res => {
						this.menus = res.data;
						callback();
					})
					.catch(err => {});
			},
			loadResources(callback) {
				axios.get('/web/resources/grouped')
					.then(res => {
						this.resources = res.data;
						callback();
					})
					.catch(err => {});
			},
			load(id) {
				axios.get(`/web/profiles/${id}`)
					.then(res => {
						let data = res.data;
						data.menu_ids = data.menus.map(m => m.id);
						data.resource_ids = data.resources.map(r => r.id);
						
						this.profile = data;
						
						delete this.profile.menus;
						delete this.profile.resources;

						Preloader.hide();
					})
					.catch(err => {});
			},
			onSubmit() {
				this.saving = true;
				let validate = this.validateForm(this.$refs.form);

				if (validate != '') {
					Flash('error', validate);
					this.saving = false;
				} else {
					axios.patch(`/web/profiles/${this.profile.id}`, this.profile)
						.then(res => {
							Swal.fire({
								title: 'Perfil atualizado com sucesso!',
								type: 'success'
							})
							.then(result => {
								window.location.href = '/profiles';
							});
						})
						.catch(err => {
							Flash('error', 'Erro ao tentar atualizar registro!');
							this.saving = false;
						});
				}
			}
		}
    }
</script>