<template>
	<div
		ref="modal"
		class="modal fade"
		tabindex="-1"
		role="dialog"
	>
		<div
			class="modal-dialog modal-dialog-centered modal-lg"
			role="document"
		>
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						<i class="fa fa-tachometer"></i>
						{{ title }}
					</h5>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						@click="onClose"
					>
			         	<span aria-hidden="true">&times;</span>
			        </button>
                </div>
				<div class="modal-body">
					<div v-if="loading" class="col-12 text-center">
						<img src="/assets/img/loading.svg">
					</div>
					<div v-if="!loading" class="row">
						<app-info
							label="Pontuação"
							:value="rating.score"
						/>
						<app-info
							label="Nível"
							:value="rating.level.full_name"
						/>
						<app-info
							label="Ranking"
							:value="rating.ranking + 'º'"
						/>
						<app-info
							label="Desafios completados"
							:value="rating.challenge.completed"
						/>
						<app-info
							label="Vitórias"
							:value="rating.wins"
						/>
						<app-info
							label="Empates"
							:value="rating.draws"
						/>
						<app-info
							label="Derrotas"
							:value="rating.losses"
						/>
						<app-info
							label="Abandonos"
							:value="rating.quits"
						/>
					</div>
				</div>
				<div class="modal-footer py-2 justify-content-start">
					<span class="pr-1 font-weight-bold">Aproveitamento</span>
					<div
						v-if="rating"
						class="progress pull-right user-select-none radius"
						style="width: 100%"
					>
                        <div 
                            class="progress-bar progress-bar-striped bg-success" 
                            role="progressbar" 
                            aria-valuemin="0" 
                            aria-valuemax="100" 
                            :aria-valuenow="rating.performance"
                            :style="{ minWidth: rating.performance + '%' }"
                        >
                            <div class="font-weight-bold">{{ rating.performance }}%</div>
                        </div>
                    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Info from '../InfoComponent';

	export default {
		components: {
			'app-info': Info,
		},
		data() {
			return {
				modal: HTMLElement,
				title: '',
				loading: true,
				rating: null
			}
		},
		props: {
			defaultTitle: { type: String, default: 'Desempenho' }
		},
		mounted() {
			this.title = this.defaultTitle;
		},
		methods: {
			open(id) {
				this.loading = true;
				this.modal = $(this.$refs.modal);
				this.title = 'Carregando...';
				this.modal.modal('show');

				axios.get(`/web/ratings/${id}`)
					.then(res => {
						this.rating = res.data;
						this.loading = false;
						this.title = `${this.defaultTitle}: ${this.rating.player.nickname}`;
					})
					.catch(err => {});
			},
			onClose() {
				this.modal.modal('hide');
			}
		}
	}
</script>