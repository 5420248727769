<template>
    <div>
        <app-page-header
            icon="fa fa-list"
            title="Fases"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Fases' }
            ]"
        />

        <app-datatable
            entity="stages"
            :allow-enable-multi="allows.enableMulti"
            :allow-disable-multi="allows.disableMulti"
            :allow-delete-multi="allows.deleteMulti"
            :options="{
                ajax: {
                    url: '/web/stages' + params
                },
                serverSide: false,
                columns: this.columns
            }"
            :actions="{
                create: {
                    allow: this.allows.create,
                    url: '/stages/create',
                },
                show: {
                    allow: this.allows.show,
                    url: '*',
                    callback: onShow
                },
                edit: {
                    allow: this.allows.edit,
                    url: '/stages/*/edit',
                },
                delete: {
                    allow: allows.delete,
                    url: '*',
                },
            }"
        />

        <app-stage-show ref="stageShow" />
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
	import DataTable from '../DataTableComponent';
    import StageShow from './StageShowComponent';
    import Link from '../forms/LinkComponent';

    export default {
        data() {
            return {
                params: ''
            }
        },
        components: {
			'app-page-header': PageHeader,
			'app-datatable': DataTable,
            'app-stage-show': StageShow,
            'app-link': Link,
		},
		props: {
			allows: Object,
			columns: Array,
            challengeId: Number,
		},
        mounted() {
            if (this.challengeId) {
                this.params = '?challenge_id=' + this.challengeId;
            }
        },
		methods: {
			onShow(id) {
                this.$refs.stageShow.open(id);
			}
		}
    }
</script>