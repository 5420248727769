<template>
	<div>
		<app-page-header
            icon="fa fa-bell-o"
            title="Cadastrar Tipo de Notificação"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '/notification_types', title: 'Tipos de Notificações' },
                { href: '', title: 'Cadastrar' },
            ]"
        />

		<div class="row">
			<div class="col-md-12">
                <div class="tile">
                    <div class="tile-body">
                        <form ref="form">
                            <div class="row">
                                <app-input
                                    placeholder=""
                                    label="Título"
                                    name="title"
                                    :minLength="0"
                                    :maxLength="120"
                                    :required="true"
                                    v-model="notificationType.title"
                                    :disabled="saving"
                                />
                                <app-input
                                    placeholder=""
                                    label="Ícone"
                                    name="icon"
                                    :minLength="0"
                                    :maxLength="255"
                                    :required="true"
                                    v-model="notificationType.icon"
                                    :disabled="saving"
                                />
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-12">
                                    <app-button 
                                        type="create" 
                                        :callback="onSubmit" 
                                        color="primary"
                                        :disabled="saving"
                                    />
                                    <app-link
                                        type="back"
                                        :url="backUrl"
                                        :disabled="saving"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
		</div>
	</div>
</template>

<script>
	import PageHeader from '../PageHeaderComponent';
    import Input from '../forms/InputComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';

	export default {
		components: {
			'app-page-header': PageHeader,
            'app-input': Input,
            'app-button': Button,
            'app-link': Link,
		},
		data() {
			return {
				notificationType: {
					title: null,
					icon: null,
				},
                saving: false,
			}
		},
        props: {
			backUrl: String,
		},
		methods: {
            onSubmit() {
                this.saving = true;
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                    this.saving = false;
                } else {
                    axios.post(`/web/notification_types`, this.notificationType)
                        .then(res => {
                            Swal.fire({
								title: 'Notificação criada com sucesso!',
								type: 'success'
							})
							.then(result => {
								window.location.href = '/notification_types';
							});
                        })
					    .catch(err => {
                            Flash('error', 'Erro ao tentar gravar registro!');
                            this.saving = false;
                        });
                }
			}
		}
	}
</script>