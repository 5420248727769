<template>
    <div
		:id="containerId"
		:class="containerClass"
	>
        <div class="animated-checkbox checkbox-xs-2">
            <label>
                <input 
                    type="checkbox" 
                    :name="name"
                    :value="value"
                    :required="required"
                    :disabled="disabled"
                    :placeholder="placeholder"
                    :checked="isChecked"
                    @input="updateValue"
                >
                <span class="label-text">{{ label }}</span>
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        model: {
            prop: 'modelValue',
            event: 'input'
        },
        props: {
            placeholder: String,
            label: String,
            name: String,
            value: null,
            required: Boolean,
            disabled: { type: Boolean, default: false },
            containerId: String,
			containerClass: { type: String, default: 'col-12 col-xl-4 col-md-6' },
            modelValue: { type: Array, required: true }
        },
        computed: {
            isChecked() {
                return this.modelValue.includes(this.value);
            }
        },
        methods: {
            updateValue(e) {
                let newValue = e.target.value;
                let updatedValue = [...this.modelValue];

                if (e.target.checked) {
                    updatedValue.push(newValue);
                } else {
                    updatedValue = updatedValue.filter(value => value != newValue);
                }
                
                this.$emit('input', updatedValue);
            }
        }
    }
</script>