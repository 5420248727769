<template>
	<div class="col-md-6">
		<div class="tile">
			<div class="row">
				<h3 class="col-7 col-xl-9 tile-title">
					Aproveitamento
				</h3>
				<div class="col-5 col-xl-3">
					<div class="input-group input-group-sm">
						<div class="input-group-prepend">
							<label class="input-group-text">
								<i class="fa fa-calendar"></i>&nbsp;Ano
							</label>
						</div>
						<input
							ref="input"
							class="form-control text-right"
							readonly="true"
							v-model="selectedYear"
						>
					</div>
				</div>
			</div>
			<div v-if="loading" class="col-12 d-flex justify-content-center">
				<img src="/assets/img/loading.svg">
			</div>
			<div
				v-if="!loading"
				class="embed-responsive embed-responsive-16by9"
			>
				<canvas
					ref="chartContainer"
					class="embed-responsive-item"
				></canvas>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				loading: false,
				chart: null,
				selectedYear: null,
			};
		},
		computed: {
			currentYear: function () {
				return new Date().getFullYear();
			}
		},
		mounted() {
			this.selectedYear = this.currentYear;
			
			$(this.$refs.input)
				.datetimepicker({
					initDate: new Date(),
					format: 'yyyy',
					language: 'pt-BR',
					todayHighlight: true,
					autoclose: true,
					fontAwesome: true,
					startView: 4,
					minView: 4,
					maxView: 4
				})
				.on('change', this.load);

			this.load({ target: { value: this.currentYear } });
		},
		methods: {
			load(e) {
				if (this.chart) this.chart.destroy();
				
				this.selectedYear = e.target.value;
				this.loading = true;

				axios.get(`/report/battles/performances?year=${this.selectedYear}`)
					.then(async res => {
						this.loading = false;
						await Help.delay(300);
						this.loadChart(res.data);
					})
					.catch(err => {});
			},
			loadChart(data) {
				this.chart = new Chart(this.$refs.chartContainer, {
					type: 'line',
					data: {
						labels: data.labels,
                        datasets: [{
                            label: this.selectedYear,
                            data: data.performance,
                            backGroundColor: 'rgba(151,187,205,0.2)',
                            borderColor: 'rgba(151,187,205,1)',
                            borderWidth: 1,
				        }],
					},
					options: {
						scales: {
                            yAxes: [{
                                ticks: {
                                    min: 0,
                                    max: 100,
                                    beginAtZero: true,
                                }
                            }]
                        },
                        tooltips: {
                            callbacks: {
                                label: function(tooltipItem, data) {
                                    return data
										.datasets[tooltipItem.datasetIndex]
                                        .data[tooltipItem.index] + '%';
                                }
                            }
                        },

                        responsive: true,
    			        maintainAspectRatio: false
					}
				});
			}
		}
	}
</script>