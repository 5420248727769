<template>
	<div
		ref="modal"
		class="modal fade"
		tabindex="-1"
		role="dialog"
	>
		<div
			class="modal-dialog modal-dialog-centered modal-lg"
			role="document"
		>
			<div class="modal-content">
				<div class="modal-header">
                    <h5 class="modal-title">
                        <i class="fa fa-list-ol"></i>
                        {{ title }}
                    </h5>
                    <button 
                        type="button" 
                        class="close" 
                        data-dismiss="modal" 
                        aria-label="Close" 
                        @click="onClose"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
				<div class="modal-body">
					<div class="row">
						<div v-if="loading" class="col-12 d-flex justify-content-center">
                            <img src="/assets/img/loading.svg">
                        </div>
						<div v-if="!loading" class="col-12">
							<ul class="nav nav-tabs" role="tablist">
								<li
									v-for="(stage, i) in stages"
									class="nav-item"
									role="presentation"
								>
									<a
										:class="[
											'nav-link btn-outline-info',
											{ 'active': i == 0 }
										]"
										data-toggle="tab"
										:aria-controls="'stage' + i"
										:href="'#stage' + i"
									>
										{{ stage.title }}
									</a>
								</li>
							</ul>
							<div class="tab-content border border-top-o rounded-bottom p-2 mb-2">
								<div
									v-for="(stage, i) in stages"
									:class="[
										'tab-pane fade',
										{ 'show active': i == 0 }
									]"
									:id="'stage' + i"
									:aria-labelledby="'stage' + i + '-tab'"
								>
									<table class="table table-striped">
										<thead>
											<tr>
												<th><i class="fa fa-trophy"></i></th>
												<th>Nome</th>
												<th>Partidas</th>
												<th>Pontos</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(rating, i) in stage.ratings">
												<td>{{ i + 1 }}°</td>
												<td>{{ rating.player.nickname }}</td>
												<td>{{ rating.battles }}</td>
												<td>{{ rating.score }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				modal: HTMLElement,
				title: '',
				loading: true,
				stages: [],
			}
		},
		props: {
			defaultTitle: { type: String, default: 'Ranking' }
		},
		mounted() {
			this.title = this.defaultTitle;
		},
		methods: {
			open(id) {
				this.loading = true;
				this.modal = $(this.$refs.modal);
				this.title = 'Carregando...';
				this.modal.modal('show');

				axios.get(`/web/tournaments/${id}/ranking`)
					.then(res => {
						this.stages = res.data;
						this.title = `${this.defaultTitle}: ${this.stages[0].tournament.title}`;
						this.loading = false;
					})
					.catch(err => { });
			},
			onClose() {
				this.modal.modal('hide');
			}
		}
	}
</script>