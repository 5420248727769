<template>
	<div
		id="step1"
		class="tab-pane fade"
		role="tabpanel"
	>
		<h3 class="text-center">O tipo do torneio</h3>
		<p>
			<b>Pontos corridos</b> é uma modalidade que designa todo tipo de
			torneio em que cada um dos competidores enfrenta todos os demais.
			Ao final, aquele que obtiver mais pontos é o campeão.
		</p>
		<app-select
			containerClass=""
			className="form-control"
			:required="true"
			:options="options"
			@input="updateValue"
		/>
		<app-arrows @click="onClickArrow" />
	</div>
</template>

<script>
	import Select from '../../forms/SelectComponent';
	import Arrows from './ArrowsComponent';

	export default {
		components: {
			'app-select': Select,
			'app-arrows': Arrows,
		},
		model: {
			prop: 'modelValue',
			event: 'input'
		},
		data() {
			return {
				options: []
			};
		},
		props: {
			value: null
		},
		mounted() {
			this.loadTournamentTypes();
		},
		methods: {
			loadTournamentTypes() {
				axios.get('/web/tournament_types/list?toTags=true')
					.then(res => {
						this.options = res.data;
					})
					.catch(err => {});
			},
			onClickArrow(direction) {
				this.$emit('click', direction);
			},
			updateValue(value) {
				this.$emit('input', value);
			}
		}
	}
</script>