<template>
    <div>
        <app-page-header
            icon="fa fa-gamepad"
            title="Desafios"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Desafios' }
            ]"
        />

        <app-datatable
            entity="challenges"
            :allow-enable-multi="allows.enableMulti"
            :allow-disable-multi="allows.disableMulti"
            :allow-delete-multi="allows.deleteMulti"
            :withStatusFilter="true"
            :options="{
                ajax: {
                    url: '/web/challenges' + params
                },
                columns: this.columns
            }"
            :actions="{
				stages: {
                    allow: true,
                    url:  '/stages?challenge_id=*'
                },
				create: {
                    allow: this.allows.create,
                    url: '/challenges/create',
                },
                show: {
                    allow: this.allows.show,
                    url: '*',
                    callback: onShow
                },
				edit: {
                    allow: this.allows.edit,
                    url: '/challenges/*/edit',
                },
				delete: {
                    allow: this.allows.delete,
                    url: '*',
                },
            }"
        />

        <app-challenge-show ref="challengeShow" />
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';              
    import DataTable from '../DataTableComponent';
    import ChallengeShow from './ChallengeShowComponent';
    import Link from '../forms/LinkComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-datatable': DataTable,
            'app-challenge-show': ChallengeShow,
            'app-link': Link
        },
        data() {
            return {
                params: ''
            }
        },
        props: {
			allows: Object,
			columns: Array,
            categoryId: Number
		},
        mounted() {
            if (this.categoryId) {
                this.params = '?category_id=' + this.categoryId;
            }
        },
        methods: {
			onShow(id) {
                this.$refs.challengeShow.open(id);
			}
		}
    }
</script>