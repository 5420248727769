<template>
	<div class="col-12 mb-2">
		<label v-if="label">
			{{ label }}
		</label>
		<div ref="treeContainer"><hr /></div>
	</div>
</template>

<script>
	export default {
		model: {
			prop: 'modelValue',
			event: 'change',
		},
		data() {
			return {
				treeContainer: null,
				tree: null,
			};
		},
		props: {
			label: String,
			options: Array,
			required: Boolean,
			modelValue: { type: Array, required: true }
		},
		watch: {
			options: function (newValue, oldValue) {
				if (this.tree) this.tree.destroy();

				this.tree = $(this.treeContainer).tree({
					primaryKey: 'id',
					uiLibrary: 'bootstrap4',
					checkboxes: true,
					border: true,
					dataSource: this.options
				})
				.on('checkboxChange', (e, node, id) => {
					const value = this.tree.getCheckedNodes().filter(value => value != null);
					this.$emit('change', value);
				});
			}
		},
		mounted() {
			this.treeContainer = this.$refs.treeContainer;
		}
	}
</script>