<template>
	<div>
		<app-page-header
            icon="fa fa-dashboard"
            title="Painel"
            :breadcrumbs="[
				{ href: '', title: 'Painel' }
			]"
        />

		<div class="row">
            <app-jumbotron :name="userName" />
        </div>
	</div>
</template>

<script>
	import PageHeader from '../PageHeaderComponent';
	import Jumbotron from '../JumbotronComponent';
	
	export default {
		components: {
			'app-page-header': PageHeader,
			'app-jumbotron': Jumbotron,
		},
		props: {
			userName: String,
		}
	}
</script>