var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-gamepad","title":"Desafios","breadcrumbs":[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Desafios' }
            ]}}),_vm._v(" "),_c('app-datatable',{attrs:{"entity":"challenges","allow-enable-multi":_vm.allows.enableMulti,"allow-disable-multi":_vm.allows.disableMulti,"allow-delete-multi":_vm.allows.deleteMulti,"withStatusFilter":true,"options":{
                ajax: {
                    url: '/web/challenges' + _vm.params
                },
                columns: this.columns
            },"actions":{
				stages: {
                    allow: true,
                    url:  '/stages?challenge_id=*'
                },
				create: {
                    allow: this.allows.create,
                    url: '/challenges/create',
                },
                show: {
                    allow: this.allows.show,
                    url: '*',
                    callback: _vm.onShow
                },
				edit: {
                    allow: this.allows.edit,
                    url: '/challenges/*/edit',
                },
				delete: {
                    allow: this.allows.delete,
                    url: '*',
                },
            }}}),_vm._v(" "),_c('app-challenge-show',{ref:"challengeShow"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }