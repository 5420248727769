<template>
	<div
		ref="modal"
		class="modal fade"
		tabindex="-1"
		role="dialog"
	>
		<div
			class="modal-dialog modal-dialog-centered modal-lg"
			role="document"
		>
			<div class="modal-content">
				<div v-if="!loading" class="modal-header border-0">
                    <img 
						:src="player.avatar.replace('*', '120')"
						class="thumb thumb-sm img-thumbnail rounded img-fluid thumb-show shadow"
					>
                    <h5 class="modal-title text-center w-100">
						<i class="fa fa-user"></i>
						{{ title }}
					</h5>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						@click="onClose"
					>
			         	<span aria-hidden="true">&times;</span>
			        </button>
                </div>
				<div class="modal-body">
					<div class="row content">
						<div v-if="loading" class="col-12 text-center">
							<img src="/assets/img/loading.svg">
						</div>
						<div v-if="!loading" class="col-12">
							<div class="row">
								<app-info
									label="ID"
									:value="player.id"
								/>
								<app-info
									label="Nome"
									:value="player.nickname"
								/>
								<app-info
									label="Nascimento"
									:value="getBirthDate"
								/>
								<app-info
									label="Idioma"
									:value="player.language_text"
								/>
								<app-info
									label="Usuário"
									:value="player.user.login"
								/>
								<app-info
									label="Cadastrado em"
									:value="new Date(player.created_at).toLocaleString()"
								/>
								<app-info
									label="Status"
									:value="player.active_text"
								/>
								<app-info
									v-if="player.user.mn_id"
									label="Ano"
									:value="player.classroom.year"
								/>
								<app-info
									v-if="player.user.mn_id"
									label="Sala"
									:value="player.classroom.title"
								/>
								<app-info
									v-if="player.user.mn_id"
									label="Escola"
									:value="player.school.name"
								/>
								<app-info
									v-if="!player.user.mn_id"
									containerClass="col-12"
									label="Email"
									:value="player.user.email"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Info from '../InfoComponent';

	export default {
		components: {
			'app-info': Info,
		},
		computed: {
			getBirthDate() {
				return (this.player.birth_date)
					? new Date(this.player.birth_date).toLocaleDateString()
					: '-';
			}
		},
		data() {
			return {
				modal: HTMLElement,
				title: '',
				loading: true,
				player: null
			}
		},
		props: {
			defaultTitle: { type: String, default: 'Jogador' }
		},
		mounted() {
			this.title = this.defaultTitle;
		},
		methods: {
			open(id) {
				this.loading = true;
				this.modal = $(this.$refs.modal);
				this.title = 'Carregando...';
				this.modal.modal('show');

				axios.get(`/web/players/${id}`)
					.then(res => {
						this.player = res.data;
						this.loading = false;
						this.title = `${this.defaultTitle}: ${this.player.id}`;
					})
					.catch(err => {});
			},
			onClose() {
				this.modal.modal('hide');
			}
		}
	}
</script>