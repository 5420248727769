<template>
    <div :class="containerClass">
        <div class="stage-board">
            <div id="chess-board"></div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
			return {
				board: null
			};
		},
		props: {
			value: String,
            editable: Boolean,
            containerClass: { type: String, default: 'col-12 col-xl-3 col-lg-4' }
		},
        mounted() {
            this.createBoard();
        },
		methods: {
            async createBoard() {
                await Help.delay(150);
                
                let options = {
                    position: this.value,
                    pieceTheme: '/assets/img/chesspieces/wikipedia/{piece}.png',
                    onChange: this.updateValue
                };
                
                if (this.editable) {
                    options.draggable = true;
                    options.dropOffBoard = 'trash';
                    options.sparePieces = true;
		        }

                this.board = new ChessBoard('chess-board', options);
            },
            async updateValue(e) {
                await Help.delay(300);
                this.$emit('input', this.board.fen());
            }
		}
    }
</script>