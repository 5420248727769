<template>
    <section class="login-content">
        <div class="logo">
	        <h1>
	            <a href="/" :title="title">
	                <img src="/assets/img/logo_email.png" :alt="title">
	            </a>
	        </h1>
	    </div>
		<div class="login-box">
	    	<form class="login-form" ref="form" autocomplete="off">
	    		<h3 class="login-head font-weight-normal">PAINEL</h3>
	    		<input
	                type="text"
	                name="login"
	                class="form-control"
	                autofocus
	                minlength="2"
	                maxlength="65"
	                placeholder="Usuário"
	                required
	                @keyup="onSubmit"
					v-model="credentials.login"
	            >
	            <input
	                type="password"
	                name="password"
	                class="form-control"
	                minlength="6"
	                maxlength="10"
	                required
	                placeholder="Senha"
	                @keyup="onSubmit"
					v-model="credentials.password"
	            >
	            <!-- ADD RECAPTCHA HERE -->
	            <button
	            	type="button"
	            	class="btn btn-primary btn-block"
	            	@click="onSubmit"
	            	:disabled="logging"
            	>
            		<span
            			v-if="logging"
            			class="spinner-border spinner-border-sm"
            			role="status"
            			aria-hidden="true"
        			></span>
            		<span v-if="!logging">Entrar</span>
            	</button>
				<button
	            	type="button"
	            	class="btn btn-secondary btn-block"
	            	@click="onRemember"
	            	:disabled="logging"
            	>
            		<span>Lembrar Senha</span>
            	</button>
	    	</form>
	    </div>
		<h6 class="version text-muted m-0 h6">v.{{ appVersion }}</h6>
    </section>
</template>

<script>
    export default {
		data() {
			return {
				logging: false,
				credentials: {
					login: '',
					password: ''
				}
			}
		},
		props: {
			title: String,
			appVersion: String,
			router: String,
		},
		methods: {
			onSubmit(e = null) {
				if (e instanceof Event && e.keyCode != 13 && e.type != 'click') return;

				this.logging = true;
				let validate = this.validateForm(this.$refs.form);

				if (validate != '') {
					this.logging = false;
					Flash('error', validate);
				} else {
					axios.post('/web/login', this.credentials)
						.then(res => {
							this.logging = false;
							window.location.href = '/';
						})
						.catch(err => {
							Flash('error', 'Usuário ou senha inválidos!');
							this.logging = false;
						});
				}
			},
			onRemember(e) {
				window.location.href = '/password/reset';
			}
		}
    }
</script>