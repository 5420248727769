<template>
    <div>
        <app-page-header
            icon="fa fa-bell-o"
            title="Tipos de Notificações"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Tipos de Notificações' },
            ]"
        />

        <app-datatable
            entity="notification_types"
            :allow-enable-multi="allows.enableMulti"
            :allow-disable-multi="allows.disableMulti"
            :allow-delete-multi="allows.deleteMulti"
            :statusFilterIndex="5"
            :options="{
                ajax: {
                    url: '/web/notification_types'
                },
                serverSide: false,
                columns: this.columns
            }"
            :actions="{
				create: {
                    allow: this.allows.create,
                    url: '/notification_types/create',
                },
                edit: {
                    allow: this.allows.edit,
                    url: '/notification_types/*/edit',
                },
            }"
        />
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import DataTable from '../DataTableComponent';
    import Link from '../forms/LinkComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-datatable': DataTable,
            'app-link': Link
        },
        props: {
			allows: Object,
			columns: Array,
			status: Array,
		},
    }
</script>