<template>
	<div
		class="tab-pane fade"
		:id="tabName"
		role="tabpanel"
	>
		<div class="header-mark text-muted">Fase {{ (id + 1) }}</div>
		<h3 class="text-center">Definindo regras da fase</h3>
		<p class="text-center">
			Aqui você irá definir as regras para os seguintes critérios:
		</p>
		<div class="mb-2">
			<h6>1. Mínimo de partidas jogadas</h6>
			<p>
				O número mínimo de partidas que um participante precisa jogar para se
				classificar (caso haja mais de 1) ou ganhar o torneio
				(caso seja fase única ou a última).
			</p>
			<h6>2. Número de vencedores por divisão</h6>
			<p>
				Número de vencedores por divisão, é o total de participantes que poderão se
				classificar para a próxima fase (caso haja mais de 1) ou ganhar o torneio
				(caso seja fase única ou a última).
			</p>
			<h6>3. Tipo de divisão</h6>
			<p>
				O tipo de divisão, determina se os participantes se classificarão em:
				<ul>
					<li>
						<b>Grupo único:</b> Nessa modalidade se classifica ou ganha
						o torneio o "número de vencedores";
					</li>
					<li>
						<b>Grupos por sala:</b> Nesse caso se classifica ou ganha
						o torneio o "número de vencedores" por sala participante;
					</li>
					<li>
						<b>Grupos por ano:</b> Já nesse caso, se classifica ou ganha
						o torneio o "número de vencedores" de acordo com os anos letivos
						das salas participantes.
					</li>
				</ul>
			</p>
		</div>
		<app-arrows @click="onClickArrow" />
	</div>
</template>

<script>
	import Arrows from '../ArrowsComponent';

	export default {
		components: {
			'app-arrows': Arrows,
		},
		props: {
			id: Number,
			tabName: String
		},
		methods: {
			onClickArrow(direction) {
				this.$emit('click', direction);
			}
		}
	}
</script>