<template>
    <div class="col-12">
        <div class="jumbotron-theme py-4 shadow-sm">
            <h1 class="h3 font-weight-light mt-0">
                Olá {{ name }}
            </h1>
            <p class="lead m-0">
                {{ dateNow }}
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            name: String,
        },
		computed: {
			dateNow: function () {
				return `Hoje é dia ${moment().format('LL')}`;
			}
		}
    }
</script>