(function(cash) {
	"use strict";

	const preloader = {
		show: (text = 'carregando aguarde...') => {
			$('.bg-overlay')
				.addClass('bg-overlay-show')
				.find('.loading-text')
				.text(text);
			$(document.body).css('overflow', 'hidden');
		},
		hide: () => {
			$('.bg-overlay').removeClass('bg-overlay-show');
			$(document.body).css('overflow', 'initial');
		}
	}

	window.Preloader = preloader;
})(cash);