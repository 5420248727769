<template>
	<div
		ref="modal"
		class="modal fade"
		tabindex="-1"
		role="dialog"
	>
		<div
			class="modal-dialog modal-dialog-centered modal-lg"
			role="document"
		>
			<div class="modal-content">
				<div class="modal-header">
                    <h5 class="modal-title">
                        <i class="fa fa-trophy"></i>
                        {{ title }}
                    </h5>
                    <button 
                        type="button" 
                        class="close" 
                        data-dismiss="modal" 
                        aria-label="Close" 
                        @click="onClose"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
				<div class="modal-body">
					<div class="row">
						<div v-if="loading" class="col-12 d-flex justify-content-center">
                            <img src="/assets/img/loading.svg">
                        </div>
						<div v-if="!loading" class="col-12">
							<div class="row">
								<app-info
									label="Título"
									:value="tournament.title"
								/>
								<app-info
									label="Tipo"
									:value="tournament.tournament_type.title"
								/>
								<app-info
									label="Status"
									:value="tournament.status_text"
								/>
								<app-tournament-stage-list :value="tournament.stages" />
								<div class="col-12">
									<div class="card">
										<div class="card-header">
                                            <h6 class="h6">Critérios de desempate</h6>
                                        </div>
										<ul class="list-group list-group-flush">
                                            <li 
                                                v-for="tiebreaker in tournament.tiebreaker_types" 
                                                class="list-group-item"
                                            >
                                                {{ tiebreaker.title.pt }}
                                            </li>
                                        </ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Info from '../InfoComponent';
	import TournamentStageList from './TournamentStageListComponent';

	export default {
		components: {
			'app-info': Info,
			'app-tournament-stage-list': TournamentStageList,
		},
		data() {
			return {
				modal: HTMLElement,
				loading: true,
				title: '',
				tournament: null,
			};
		},
		props: {
			defaultTitle: { type: String, default: 'Torneio' }
		},
		mounted() {
			this.title = this.defaultTitle;
		},
		methods: {
			open(id) {
				this.loading = true;
				this.modal = $(this.$refs.modal);
				this.title = 'Carregando...';
				this.modal.modal('show');

				axios.get(`/web/tournaments/${id}`)
					.then(res => {
						this.tournament = res.data;
						this.title = `${this.defaultTitle}: ${this.tournament.id}`;
						this.loading = false;
					})
					.catch(err => {});
			},
			onClose() {
				this.modal.modal('hide');
			}
		}
	}
</script>