var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-bell-o","title":"Tipos de Notificações","breadcrumbs":[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Tipos de Notificações' },
            ]}}),_vm._v(" "),_c('app-datatable',{attrs:{"entity":"notification_types","allow-enable-multi":_vm.allows.enableMulti,"allow-disable-multi":_vm.allows.disableMulti,"allow-delete-multi":_vm.allows.deleteMulti,"statusFilterIndex":5,"options":{
                ajax: {
                    url: '/web/notification_types'
                },
                serverSide: false,
                columns: this.columns
            },"actions":{
				create: {
                    allow: this.allows.create,
                    url: '/notification_types/create',
                },
                edit: {
                    allow: this.allows.edit,
                    url: '/notification_types/*/edit',
                },
            }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }