<template>
	<div class="buttons">
		<a
			v-if="!prevHide"
			href="javascript:"
			class="button prev"
			data-content="Anterior"
			data-toggle="popover"
			data-id="prev"
			@click="onClick"
		>
			<i class="fa fa-angle-left"></i>
		</a>
		<a
			href="javascript:"
			:class="[
				'button next',
				{ 'disabled': disabled }
			]"
			data-content="Próximo"
			data-toggle="popover"
			data-id="next"
			:disabled="disabled"
			@click="onClick"
		>
			<i class="fa fa-angle-right"></i>
		</a>
	</div>
</template>

<script>
	export default {
		props: {
			prevHide: { type: Boolean, default: false },
			disabled: Boolean
		},
		methods: {
			onClick(e) {
				this.$emit('click', $(e.currentTarget).data('id'));
			}
		}
	}
</script>