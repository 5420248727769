var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-user","title":"Gerentes","breadcrumbs":[
            { href: '/', title: 'Painel' },
            { href: '', title: 'Gerentes' },
        ]}}),_vm._v(" "),_c('app-datatable',{attrs:{"entity":"managers","allow-enable-multi":_vm.allows.enableMulti,"allow-disable-multi":_vm.allows.disableMulti,"allow-delete-multi":_vm.allows.deleteMulti,"withStatusFilter":true,"options":{
            ajax: {
                url: '/web/managers'
            },
            columns: this.columns
        },"actions":{
            show: {
                allow: this.allows.show,
                url: '*',
                callback: _vm.onShow
            },
        }}}),_vm._v(" "),_c('app-manager-show',{ref:"managerShow"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }