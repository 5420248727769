<template>
    <div class="row">
		<div id="content" class="col-md-12">
			<form ref="multiForm">
				<div class="tile">
					<div class="dt-buttons">
						<app-link
							v-if="allowEnableMulti"
							type="enableMulti"
							:onClick="onEnableMulti"
						></app-link>
						<app-link
							v-if="allowDisableMulti"
							type="disableMulti"
							:onClick="onDisableMulti"
						></app-link>
						<app-link
							v-if="allowDeleteMulti"
							type="deleteMulti"
							:onClick="onDeleteMulti"
						></app-link>
					</div>
					<hr />
					<table class="table table-striped display responsive nowrap" id="table">
						<thead></thead>
						<tbody></tbody>
					</table>
				</div>
			</form>
		</div>
		
        <app-side-filter
			ref="filter"
			:onChangeCallback="onChangeSideFilter"
		></app-side-filter>

		<app-link
			v-if="actions && actions.create && actions.create.allow"
			type="create"
			:url="actions.create.url"
			data-toggle="popover"
			data-content="Cadastrar Novo"
			title=""
		></app-link>
	</div>
</template>

<script>
    import SideFilter from './SideFilterComponent';
	import Link from './forms/LinkComponent';
	import ProfileType from '../enums/ProfileType';

    export default {
        components: {
			'app-side-filter': SideFilter,
			'app-link': Link
		},
		props: {
			entity: String,
			options: Object,
			actions: Object,
			filters: { type: Array, default: () => { return []; } },
			allowEnableMulti: Boolean,
			allowDisableMulti: Boolean,
			allowDeleteMulti: Boolean,
			withStatusFilter: { type: Boolean, default: false },
		},
		data() {
			return {
				datatables: null,
				defaultOptions: { 
					lengthMenu: [10, 30, 50, 100],
					processing: true,
					ordering: true,
					searching: true,
					serverSide: true,
					deferRender: true,
					responsive: true,
					autoWidth: false,
					order: [[1, 'asc']],
					language: {
						sEmptyTable: "Nenhum registro encontrado",
						sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
						sInfoEmpty: "Mostrando 0 até 0 de 0 registros",
						sInfoFiltered: "(Filtrados de _MAX_ registros)",
						sInfoPostFix: "",
						sInfoThousands: ".",
						sLengthMenu: "_MENU_",
						sLoadingRecords: "Carregando...",
						sProcessing: "Processando...",
						sZeroRecords: "Nenhum registro encontrado",
						sSearch: "",
						sSearchPlaceholder: "Pesquisar",
						oPaginate: {
							sNext: "Próximo",
							sPrevious: "Anterior",
							sFirst: "Primeiro",
							sLast: "Último"
						},
						oAria: {
							sSortAscending: ": Ordenar colunas de forma ascendente",
							sSortDescending: ": Ordenar colunas de forma descendente"
						}
					}
				},
				activeFieldIndex: 0,
			};
		},
        mounted() {
			this.loadEventsInCollapsed();
			this.addScripts([
                '/assets/js/plugins/jquery.dataTables.min.js',
				'/assets/js/plugins/dataTables.bootstrap.min.js',
				'/assets/js/plugins/dataTables.responsive.min.js',
				'/assets/js/plugins/dataTables.buttons.min.js',
				'/assets/js/plugins/buttons.print.min.js',
				'/assets/js/plugins/bs-breakpoints.min.js',
				'/assets/js/DataTables.js',
            ], this.callback);
        },
        methods: {
			init() {
				this.datatables.init();
			},
			reload() {
				this.datatables.table.ajax.reload();
			},
			setLoading(type) {
				$('.bg-overlay')[type + 'Class']('bg-overlay-show');
			},
            callback() {
				this.loadFilters();
				
				if (this.withStatusFilter && PROFILE_CODE === ProfileType.MANAGER) {
					this.activeFieldIndex = this.options.columns.length - 1;
					this.loadStatusFilter();
				} else if (this.filters.length == 0) {
					this.options.btFilter = false;
				}

                if (Object.keys(this.actions).length) {
					this.options.actions = this.formatDatatablesButtons(this.actions);
					
					this.options.columns.push({
                        title: 'Ações',
                        orderable: false,
                        searchble: false,
                    });
				}
				
				let options = { ...this.defaultOptions, ...this.options };
				
				this.datatables = new DataTables(options, options.print);
				this.datatables.onLoaded = this.setClickEvents;
				this.datatables.onReloadEvents = this.setClickEvents;

            },
			loadEventsInCollapsed() {
				let element = $('#table');
				let observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						setTimeout(() => {
							let e = $(mutation.target).parent().next()[0];
							if (e && e.className == 'child') {
								this.setClickEvents();
							}
						}, 300);
					});
				});

				observer.observe(element[0], {
					attributes: true,
					subtree: true
				});
			},
			loadFilters() {
				for (let filter of this.filters) {
					this.$refs.filter.add(filter);
				}
			},
			loadStatusFilter() {
				this.$refs.filter.add({
					name: 'active',
					type: 'select',
					index: this.activeFieldIndex,
					label: 'Status',
					options: [
						{ 'value': '0', 'label': 'Inativo' },
            			{ 'value': '1', 'label': 'Ativo' }
					]
				});
			},
			onChangeSideFilter(data) {
				if (this.datatables) {
					this.datatables.filter(data);
				}
			},
            onEnableMulti() {
				this.onConfirmMultiAction(
					'Ativar?',
					'Deseja realmente ativar selecionados?',
					`/web/${this.entity}/enableMulti`
				);
			},
			onDisableMulti() {
				this.onConfirmMultiAction(
					'Desativar?',
					'Deseja realmente desativar selecionados?',
					`/web/${this.entity}/disableMulti`
				);
			},
			onDeleteMulti() {
				this.onConfirmMultiAction(
					'Deletar?',
					'Deseja realmente excluir selecionados?',
					`/web/${this.entity}/deleteMulti`
				);
			},
            onConfirmMultiAction(title, text, url) {
				Swal.fire({
					type: 'warning',
			        title: title,
			        text: text,
			        showCancelButton: true,
			        confirmButtonText: 'Ok',
			        cancelButtonText: 'Cancelar',
				}).then((res) => {
					if (res.value) {
						if ($(this.$refs.multiForm).find('[type="checkbox"]:checked').length) {
							this.executeMultiAction(url);
						} else {
							Swal.fire({
								type: 'warning',
								title: 'Atenção!',
								text: 'Selecione pelo menos um registro!'
							});
						}
					}
				});
			},
            executeMultiAction(url) {
				let data = new FormData(this.$refs.multiForm);
				data.append('_method', 'PATCH');

				axios.post(url, data)
					.then(res => {
						this.reload();
						Flash('success', 'Atualizado com sucesso!');
					})
					.catch(err => {
						Flash('error', 'Não foi possível executar a ação, tente novamente!');
					});
			},
			setClickEvents() {
				cash('.table [data-id]').off('click');
				cash('.table [data-id]').on('click', this.onClick);
				
				if ($('.colorbox').length) {
					$('.colorbox').colorbox({
                        width: '80%',
                        height: '96%',
                        iframe: true,
						onOpen: () => {
							$(document.body).addClass('overflow-hidden');
						},
						onClosed: () => {
							$(document.body).removeClass('overflow-hidden');
						}
                    });
				}
			},
			onClick(e) {
				let id = cash(e.currentTarget).data('id');
				let type = cash(e.currentTarget).data('type');
				switch (type) {
					case 'delete':
						this[cash(e.currentTarget).data('function')](id);
						break;
					default:
						this.actions[type].callback(id);
				}
			},
			onDelete(id) {
				Swal.fire({
			        type: 'warning',
			        title: 'Deletar?',
			        text: 'Deseja realmente excluir este cadastro?',
			        showCancelButton: true,
			        confirmButtonText: 'Deletar',
			        cancelButtonText: 'Cancelar',
			    }).then((response) => {
			        if (response.value) {
			            axios.delete(`/web/${this.entity}/${id}`)
							.then(res => {
								this.reload();
								Flash('success', 'Atualizado com sucesso!');
							})
							.catch(err => {
								Flash('error', 'Erro ao tentar deletar registro!');
							});
			        }
			    });
			},
			setNewOptions(fieldName, data) {
				this.$refs.filter.setNewOptions(fieldName, data);
			}
        }
    }
</script>