<template>
	<div class="col-12">
		<ul class="nav nav-tabs" role="tablist">
			<li 
				v-for="(stage, i) in value" 
				class="nav-item" 
				role="presentation"
			>
				<a 
					:href="'#stage' + stage.id" 
					class="nav-link btn-outline-info"
					:class="[(i == 0) ? 'active' : '']" 
					data-toggle="tab" role="tab"
					:aria-controls="'stage' + stage.id" 
					:id="'stage' + stage.id + '-tab'"
				>
					{{ stage.title }}
				</a>
			</li>
		</ul>
		<div class="tab-content border border-top-0 rounded-bottom p-2 mb-2">
			<div 
				v-for="(stage, i) in value" 
				class="tab-pane fade"
				:class="[(i == 0) ? 'show active' : '']" 
				:id="'stage' + stage.id"
				role="tabpanel"
				:aria-labelledby="'stage' + stage.id + '-tab'"
			>
				<div class="row">
					<app-info
						label="Início da fase"
						:value="new Date(stage.starts_in).toLocaleString()"
					/>
					<app-info
						label="Término da fase"
						:value="new Date(stage.ends_in).toLocaleString()"
					/>
					<app-info
						label="Status"
						:value="stage.status_text"
					/>
					<app-info
						label="Jogadores"
						:value="stage.ratings.length"
					/>
					<div class="col-12">
						<h6 class="h6 border-bottom p-2 alert-info rounded">Regras</h6>
						<div class="row">
							<app-info
								containerClass="col-12 col-lg-4"
								label="Mínimo de partidas jogadas"
								:value="stage.min_battles"
							/>
							<app-info
								containerClass="col-12 col-lg-4"
								label="Número de vencedores por divisão"
								:value="stage.next_stage_amount"
							/>
							<app-info
								containerClass="col-12 col-lg-4"
								label="Tipo de divisão"
								:value="stage.next_stage_type_text"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Info from '../InfoComponent';

	export default {
		components: {
			'app-info': Info,
		},
		props: {
			value: []
		}
	}
</script>