<template>
	<div
		class="tab-pane fade"
		:id="tabName"
		role="tabpanel"
	>
		<div class="header-mark text-muted">Fase {{ (id + 1) }}</div>
		<h3 class="text-center">Qual o nome da fase?</h3>
		<p class="text-center">
			Escolha um nome para a fase {{ (id + 1) }}.<br>
		</p>
		<p class="text-center">
			É sugerido que o nome identifique o tipo de fase.<br>
			Exemplos: "Eliminatórias" ou "Final".<br>
		</p>
		<p class="text-center text-muted">
			Lembrando que você poderá criar de 1 até 3 fases para seu torneio.
		</p>
		<app-input
			ref="input"
			containerClass=""
			:required="true"
			:minLength="2"
			:maxLength="80"
			title="mínimo 2, máximo 80 caracters"
			placeholder=" Digite o nome da fase ex: Eliminatórias"
			@input="updateValue"
		/>
		<app-arrows
			:disabled="!btNextEnabled"
			@click="onClickArrow"
		/>
	</div>
</template>

<script>
	import Input from '../../../forms/InputComponent';
	import Arrows from '../ArrowsComponent';

	export default {
		components: {
			'app-input': Input,
			'app-arrows': Arrows,
		},
		model: {
			prop: 'modelValue',
			event: 'input',
		},
		data() {
			return {
				btNextEnabled: false
			};
		},
		props: {
			id: Number,
			tabName: String,
		},
		methods: {
			onClickArrow(direction) {
				this.$emit('click', direction);
			},
			updateValue(value) {
				this.$emit('input', value);

				const field = $(this.$refs.input.$el).find('input');
				this.btNextEnabled = this.validate(field);
			}
		}
	}
</script>