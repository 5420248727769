<template>
    <div v-if="notification">
        <app-page-header
            icon="fa fa-cog"
            :title="'Editar Notificação: ' + notification.id"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '/notifications', title: 'Notificações' },
                { href: '', title: 'Editar' },
            ]"
        />

        <div class="row">
            <div class="col-md-12">
                <div class="tile">
                    <div class="tile-body">
                        <form ref="form">
                            <div class="row">
                                <app-select
                                    name="notification_type_id"
                                    label="Tipo"
                                    title="Tipo"
                                    :required="true"
                                    :options="notifications_type"
                                    v-model="notification.notification_type_id"
                                    :disabled="saving"
                                />
                                <app-input
                                    placeholder=""
                                    label="Link"
                                    :minLength="0"
                                    :maxLength="120"
                                    v-model="notification.link"
                                    :disabled="saving"
                                />
                                <app-input
                                    label="Url Imagem"
                                    :minLength="2"
                                    :maxLength="120"                                    
                                    v-model="notification.image"
                                    :disabled="saving"
                                />
                                <app-input-multi
                                    name="title"
                                    placeHolder="Título"
                                    :minLength="1"
                                    :maxLength="150"
									:required="true"
									v-model="notification.title"
                                    :disabled="saving"
                                />
                                <app-input-multi
									name="message"
                                    containerClass="col-12 col-xl-6"
                                    placeHolder="Mensagem"
                                    :minLength="1"
                                    :maxLength="255"
									:required="true"
                                    v-model="notification.message"
                                    :disabled="saving"
                                />
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-12">
                                    <app-button 
                                        type="update" 
                                        :callback="onSubmit" 
                                        color="primary"
                                        :disabled="saving"
                                    />
                                    <app-link
                                        type="back"
                                        :url="backUrl"
                                        :disabled="saving"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Select from '../forms/SelectComponent';
    import Input from '../forms/InputComponent';
    import InputMulti from '../forms/InputMultiLanguageComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
            'app-select': Select,
            'app-input': Input,
            'app-input-multi': InputMulti,
            'app-button': Button,
            'app-link': Link
		},
        data() {
			return {
                notification: null,
                notifications_type: [],
                saving: false,
			}
		},
        props: {
			id: String,
			backUrl: String,
		},
        mounted() {
            Preloader.show();
            this.loadNotificationsType(() => {
                this.load(this.id);
            });
		},
        methods: {
            loadNotificationsType(callback) {
                axios.get('/web/notification_types/list?toTags=true')
					.then(res => {
						this.notifications_type = res.data;
                        callback();
					})
					.catch(err => {});
            },
            load(id) {
				axios.get(`/web/notifications/${id}`)
					.then(res => {
                        this.notification = res.data;
                        Preloader.hide();
					})
					.catch(err => {});
			},
            onSubmit() {
                this.saving = true;
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                    this.saving = false;
                } else {
                    axios.put(`/web/notifications/${this.notification.id}`, this.notification)
                        .then(res => {
                            Swal.fire({
                                title: 'Notificação atualizada com sucesso!',
                                type: 'success'
                            })
                            .then(result => {
                                window.location.href = '/notifications';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar atualizar registro!');
                            this.saving = false;
                        });
                }
            }
        }
    }
</script>