<template>
	<div class="tab-content">
		<app-title
			:id="id"
			:tabName="'step4-' + id + '-0'"
			v-model="stage.title"
			@click="onArrowClick"
			@input="updateStage"
		/>
		<app-datetime
			:id="id"
			:tabName="'step4-' + id + '-1'"
			title="Início da fase"
			subtitle="Defina a data e o horário em que essa fase iniciará."
			placeholder=" Escolha a data e hora de início"
			v-model="stage.starts_in"
			@click="onArrowClick"
			@input="updateStage"
		/>
		<app-datetime
			:id="id"
			:tabName="'step4-' + id + '-2'"
			title="Término da fase"
			subtitle="Defina a data e o horário em que essa fase encerrará."
			placeholder=" Escolha a data e hora de término"
			v-model="stage.ends_in"
			@click="onArrowClick"
			@input="updateStage"
		/>
		<app-rules-introduction
			:id="id"
			:tabName="'step4-' + id + '-3'"
			@click="onArrowClick"
		/>
		<app-number
			:id="id"
			:tabName="'step4-' + id + '-4'"
			title="Qual o mínimo de partidas?"
			subtitle="Defina o número mínimo de partidas que um participante precisa jogar para
				se classificar (caso haja mais de uma fase) ou ganhar o torneio
				(caso seja fase única ou a última)"
			placeholder=" Digite o número de partidas"
			v-model="stage.min_battles"
			@click="onArrowClick"
			@input="updateStage"
		/>
		<app-number
			:id="id"
			:tabName="'step4-' + id + '-5'"
			title="Qual o número de classificados por divisão?"
			subtitle="Defina o total de participantes que poderão se classificar
				(caso haja mais de uma fase) ou ganhar o torneio
				(caso seja fase única ou a última)"
			placeholder=" Digite o número de classificados"
			v-model="stage.next_stage_amount"
			@click="onArrowClick"
			@input="updateStage"
		/>
		<app-classified-division
			:id="id"
			:tabName="'step4-' + id + '-6'"
			v-model="stage.next_stage_type"
			@click="onArrowClick"
			@input="updateStage"
		/>
	</div>
</template>

<script>
	import Title from './stages/TitleComponent';
	import Datetime from './stages/DatetimeComponent';
	import RulesIntroduction from './stages/RulesIntroductionComponent';
	import Number from './stages/NumberComponent';
	import ClassifiedDivision from './stages/ClassifiedDivisionComponent';

	export default {
		components: {
			'app-title': Title,
			'app-datetime': Datetime,
			'app-rules-introduction': RulesIntroduction,
			'app-number': Number,
			'app-classified-division': ClassifiedDivision
		},
		model: {
			prop: 'modelValue',
			event: 'change'
		},
		data() {
			return {
				stage: {
					title: null,
					starts_in: null,
					ends_in: null,
					min_battles: null,
					next_stage_amount: null,
					next_stage_type: 0,
				}
			};
		},
		props: {
			id: Number,
			value: null,
		},
		methods: {
			onArrowClick(direction) {
				this.$emit('click', direction);
			},
			updateStage() {
				this.$emit('change', this.stage);
			}
		}
	}
</script>