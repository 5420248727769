<template>
    <footer class="footer mt-auto py-3">
		<div class="container-fluid align-middle">
            <p class="pull-left align-middle text-left m-0 bt">
                <!-- <app-link
                    type="other"
                    title="Perguntas Frequentes"
                    icon="question"
                    color="info"
                    :onClick="loadFaqs"
                ></app-link> -->
            </p>
			<div class="pull-right text-muted text-right">
				<p>v.{{ appVersion }} - Mentes Notáveis © 2012/{{ currentYear }}</p>
			</div>
		</div>
	</footer>
</template>

<script>
    import Link from './forms/LinkComponent';

    export default {
        components: {
            'app-link': Link
        },
        props: {
            appVersion: String,
			currentYear: String,
        },
        methods: {
            /* loadFaqs: () => {
                window.location.href = '/faqs'
            } */
        }
    }
</script>