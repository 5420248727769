<template>
	<div
		class="tab-pane fade"
		:id="tabName"
		role="tabpanel"
	>
		<div class="header-mark text-muted">Fase {{ (id + 1) }}</div>
		<h3 class="text-center">{{ title }}</h3>
		<p class="text-center">
			{{ subtitle }}
		</p>
		<app-input
			ref="input"
			containerClass=""
			type="datetime"
			:minLength="19"
			:maxLength="19"
			:required="true"
			:readonly="true"
			:placeholder="placeholder"
			@input="updateValue"
		/>
		<app-arrows
			:disabled="!btNextEnabled"
			@click="onClickArrow" 
		/>
	</div>
</template>

<script>
	import Input from '../../../forms/InputComponent';
	import Arrows from '../ArrowsComponent';

	export default {
		components: {
			'app-input': Input,
			'app-arrows': Arrows,
		},
		model: {
			prop: 'modelValue',
			event: 'input',
		},
		data() {
			return {
				btNextEnabled: false,
			};
		},
		props: {
			id: Number,
			tabName: String,
			title: String,
			subtitle: String,
			placeholder: String,
		},
		methods: {
			onClickArrow(direction) {
				this.$emit('click', direction);
			},
			updateValue(value) {
				this.$emit('input', value);

				const field = $(this.$refs.input.$el).find('input');
				this.btNextEnabled = this.validate(field);
			}
		}
	}
</script>