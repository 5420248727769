<template>
	<div class="row">
		<div
			v-for="group in value"
			class="col-12 col-xl-3 col-md-4 px-1"
		>
			<div class="list-group mb-2 px-0">
				<div class="list-group-item list-group-item-secondary">
					<span v-if="name == 'menus'">
						<span v-if="group.value[0].group_name">
							{{ group.value[0].group_name }}
						</span>
						<span v-if="!group.value[0].group_name">-</span>
					</span>
					<span v-if="name != 'menus'">{{ group.value[0].controller }}</span>
				</div>
				<div class="list-group-item">
					<app-toggle
						v-for="option in group.value"
						:key="option.id"
						containerClass="p-0"
						subClass="d-flex justify-content-between align-items-center m-0"
						:label="(name == 'menus') ? option.title : option.action"
						:value="option.id"
						v-model="values"
						@input="updateValue"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Toggle from './ToggleComponent.vue';

	export default {
		components: {
			'app-toggle': Toggle
		},
		model: {
			prop: 'modelValue',
			event: 'input'
		},
		data() {
			return {
				values: [...this.modelValue]
			};
		},
		props: {
			name: String,
			list: Array,
			value: null,
			modelValue: { type: Array, required: true },
		},
		methods: {
			updateValue(newValues) {
				this.$emit('input', newValues);
			}
		}
	}
</script>