<template>
    <div v-if="user">
        <app-page-header
            icon="fa fa-user"
            :title="'Editar Usuário: ' + user.id"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Editar Perfil' },
            ]"
        />
    
        <div class="row">
            <div class="col-md-12">
                <div class="tile">
                    <div class="tile-body">
                        <form>
                            <div class="row">
                                <div class="col-12 col-xl-2 col-lg-3">
                                    <div class="row">
                                        <app-image
                                            ref="imageManager"
                                            :url="user.image"
                                            :default="DEFAULT_IMAGE"
                                            @onLoaded="saveImage"
                                            @onDelete="deleteImage"
                                        />
                                    </div>
                                </div>
                                <app-input
                                    title="mínimo 6 caracters"
                                    name="name"
                                    label="Nome Completo"
                                    placeholder="Nome Completo"
                                    :maxLength="65"
                                    :minLength="6"
                                    :required="false"
                                    :disabled="true"
                                    :value="user.name"
                                />
                                <app-input
                                    containerClass="col-12 col-xl-3 col-md-6"
                                    title="apenas letras e números, mínimo 4 caracteres"
                                    name="user"
                                    label="Usuário"
                                    placeholder="Usuário"
                                    :maxLength="11"
                                    :minLength="4"
                                    :required="false"
                                    :disabled="true"
                                    :value="user.login"
                                />
                                <app-select
                                    containerClass="col-12 col-xl-3 col-md-6"
                                    label="Setor"
                                    title="Setor"
                                    :required="false"
                                    :options="profiles"
                                    :disabled="true"
                                    :value="user.profile.id"
                                />
                            </div>
                            <!-- <hr>
                            <div class="row">
                                <div class="col-12">
                                    <app-button 
                                        type="update" 
                                        :callback="onSubmit" 
                                        color="primary"
                                    />
                                    <app-link type="back" :url="backUrl" />
                                </div>
                            </div> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Button from '../forms/ButtonComponent';
    import Input from '../forms/InputComponent';
    import Select from '../forms/SelectComponent';
    import Link from '../forms/LinkComponent';
    import ImageManager from '../ImageManagerComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
            'app-button': Button,
            'app-input': Input,
            'app-select': Select,
            'app-link': Link,
            'app-image': ImageManager,
		},
        data() {
			return {
                imageManager: null,
                profiles: [],
                user: null,
                DEFAULT_IMAGE: '/assets/img/default_thumbs/user.png',
			}
		},
		props: {
			id: Number,
            backUrl: String
		},
        mounted() {
            Preloader.show();
            this.loadProfiles(() => {
                this.load(this.id);
            });
		},
		methods: {
            load(id) {
                axios.get(`/web/users/${id}`)
					.then(async res => {
                        this.user = res.data;

                        await Help.delay(300);

                        this.imageManager = this.$refs.imageManager;
                        Preloader.hide();
					})
					.catch(err => {});
			},
            loadProfiles(callback) {
                axios.get('/web/profiles/list?toTags=true')
					.then(res => {
						this.profiles = res.data;
                        callback();
					})
					.catch(err => {});
            },
            saveImage(image) {
                Preloader.show();

                axios.patch(`/web/users/${this.id}/image`, { image })
                    .then(res => {
                        Flash('success', 'Image salva com sucesso!');
                        Preloader.hide();
                    })
                    .catch(err => {
                        Flash('error', 'Erro ao tentar salvar imagem!');
                        Preloader.hide();
                    });
            },
            deleteImage() {
                Preloader.show();

                axios.delete(`/web/users/${this.id}/image`)
                    .then(res => {
                        Flash('success', 'Image removida com sucesso!');
                        this.imageManager.clear();
                        Preloader.hide();
                    })
                    .catch(err => {
                        Flash('error', 'Erro ao tentar remover imagem!');
                        Preloader.hide();
                    });
            }
            // async onSubmit() {
                // let validate = this.validateForm(this.$refs.form);

                // if (validate != '') {
                //     Flash('error', validate);
                // } else {
                    // let data = Object.fromEntries(formData);

                    // axios.put(`/web/teachers/${this.teacher_id}`, data)
                    //     .then(res => {
                    //         Swal.fire({
                    //             title: 'Salvo com sucesso!',
                    //             type: 'success'
                    //         })
                    //         .then(() => {
                    //             window.location.href = '/';
                    //         });
                    //     })
                    //     .catch(err => {
                    //         Flash('error', 'Erro ao tentar gravar registro!');
                    //     });
                // }
            // }
		}
    }
</script>