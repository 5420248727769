<template>
	<div :class="containerClass">
		<p><strong>{{ label }}</strong></p>
		<ul class="list-group">
			<li v-for="lang in LANGUAGES" class="list-group-item">
				<b>{{ lang }}:</b>
				{{ value[lang] }}
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				LANGUAGES: ['pt', 'en', 'es']
			}
		},
		props: {
			containerClass: { type: String, default: "col-12 col-xl-3 col-md-6 mt-2" },
			label: String,
			value: { type: Object, default: { pt: '', en: '', es: '' } },	
		}
	}
</script>