<template>
    <div v-if="resource">
        <app-page-header
			icon="fa fa-cog"
			:title="'Editar Menu: ' + id"
			:breadcrumbs="[
				{ href: '/', title: 'Painel' },
				{ href: '/resources', title: 'Recursos' },
				{ href: '', title: 'Editar' },
			]"
		/>

        <div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
                        <form ref="form">
							<div class="row">
                                <app-input
                                    name="controller"
                                    label="Nome do Controlador"
                                    placeholder="Nome do Controlador"
                                    :required="true"
                                    v-model="resource.controller"
                                    :disabled="saving"
                                />
                                <app-input
                                    name="action"
                                    label="Ação"
                                    placeholder="Ação"
                                    :required="true"
                                    v-model="resource.action"
                                    :disabled="saving"
                                />
                                <app-toggle
                                    label="Status"
                                    v-model="resource.active"
                                    :disabled="saving"
                                />
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-button
                                        type="update"
                                        :callback="onSubmit"
                                        :disabled="saving"
                                    />
                                    <app-link
                                        type="back"
                                        :url="backUrl"
                                        :disabled="saving"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Input from '../forms/InputComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';
    import Toggle from '../forms/ToggleComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-input': Input,
            'app-button': Button,
            'app-link': Link,
            'app-toggle': Toggle,
        },
        data() {
            return {
                resource: null,
                saving: false,
            }
        },
        props: {
            id: String,
            backUrl: String,
        },
        mounted() {
            this.load(this.id);
        },
        methods: {
            load(id) {
                Preloader.show();
                axios.get(`/web/resources/${id}`)
                    .then(res => {
                        this.resource = res.data;
                        Preloader.hide();
                    })
                    .catch(err => {});
            },
            onSubmit() {
                this.saving = true;
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                    this.saving = false;
                } else {
                    axios.patch(`/web/resources/${this.resource.id}`, this.resource)
                        .then(res => {
                            Swal.fire({
                                title: 'Salvo com sucesso!',
                                type: 'success'
                            })
                            .then(() => {
                                window.location.href = '/resources';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar atualizar registro!');
                            this.saving = false;
                        });
                }
            }
        }
    }
</script>