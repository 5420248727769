<template>
    <div
        ref="modal"
        class="modal fade"
        tabindex="-1"
        role="dialog"
    >
		<div
            class="modal-dialog modal-dialog-centered modal-xl"
            role="document"
        >
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						<i class="fa fa-list"></i>
						{{ title }}
					</h5>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						@click="onClose"
					>
			         	<span aria-hidden="true">&times;</span>
			        </button>
				</div>
				<div class="modal-body">
                    <div class="row">
						<div v-if="loading" class="col-12 d-flex justify-content-center">
							<img src="/assets/img/loading.svg">
						</div>
						<div v-if="!loading" class="col-12">
							<div class="row">
                                <app-chessboard :value="stage.fen" :editable="false"/>
                                <app-info-multi
                                    label="Título"
                                    :value="stage.title"
                                />
                                <app-info-multi
                                    label="Desafio"
                                    :value="stage.challenge.title"
                                />
                                <div class="col-12 col-xl-3 mt-2">
                                    <p><strong>Objetivos</strong></p>
                                    <ul class="list-group">
                                        <li 
                                            v-for="objective in stage.objectives" 
                                            class="list-group-item"
                                        >
                                            {{ objective.title }}
                                        </li>
                                    </ul>
                                </div>
                                <app-info
                                    containerClass="col-12 col-xl-2 mt-2"
                                    label="Movimentos mínimos"
                                    :value="stage.moves_min"
                                />
                                <app-info
                                    containerClass="col-12 col-xl-2 mt-2"
                                    label="Movimentos máximos"
                                    :value="stage.moves_max"
                                />
                                <app-info
                                    containerClass="col-12 col-xl-2 mt-2"
                                    label="Status"
                                    :value="stage.active_text"
                                />
                                <app-info-multi
                                    containerClass="col-12 col-xl-6 mt-2"
                                    label="Descrição"
                                    :value="stage.description"
                                />
                            </div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    import Chessboard from '../ChessboardComponent';
    import Info from '../InfoComponent.vue';
    import InfoMulti from '../InfoMultiComponent.vue';

    export default {
        components: {
            'app-chessboard': Chessboard,
            'app-info': Info,
            'app-info-multi': InfoMulti
        },
        data() {
			return {
				modal: HTMLElement,
				title: '',
				loading: true,
				stage: null,
			}
		},
		props: {
			defaultTitle: { type: String, default: 'Fase' }
		},
		mounted() {
			this.title = this.defaultTitle;
		},
        methods: {
            open(id) {       
                this.loading = true;
				this.modal = $(this.$refs.modal);
				this.title = 'Carregando...';
				this.modal.modal('show');

                axios.get(`/web/stages/${id}`)
					.then(res => {
                        this.stage = res.data;
                        this.loading = false;
						this.title = `${this.defaultTitle}: ${id}`;
					})
					.catch(err => {});
            },
            onClose() {
				this.modal.modal('hide');
			}
        }
    }
</script>