<template>
	<button
		:id="id"
		:title="titleData"
		:class="[
			`btn btn-circle btn-${outlineClass}${color} ${className}`,
			{ 'btn-circle-title' : withTitle },
			customClass
		]"
		:disabled="disabled"
		type="button"
		:data-toggle="dataToggle"
		:data-content="dataContent"
		@click="callback"
	>
		<i :class="'fa fa-' + icon"></i>
		<span :class="{ 'd-none': true, 'd-lg-inline-block': withTitle }">
			{{ titleData }}
		</span>
	</button>
</template>

<script>
	export default {
		data() {
			return {
				outlineClass: '',
				titleData: '',
			}
		},
		props: {
			id: String,
			icon: { type: String, default: 'floppy-o' },
			title: { type: String, default: 'Cadastrar' },
			color: { type: String, default: 'primary' },
			type: String,
			outline: { type: Boolean, default: true },
			customClass: String,
			className: { type: String, default: 'btn-circle-sm btn-table' },
			withTitle: { type: Boolean, default: true },
			disabled: Boolean,
			dataToggle: String,
			dataContent: String,
			callback: { type: Function, default: () => {} },
		},
		mounted() {
			this.outlineClass = (this.outline) ? 'outline-' : '';

			if (this.type == 'update') {
				this.titleData = 'Salvar Alterações';
			} else {
				this.titleData = this.title;
			}
		}
	}
</script>
