<template>
     <div 
        :id="containerId"
		:class="containerClass"
    >
        <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item" role="presentation">
                <a
                    :href="'#pt'+name"
                    class="nav-link btn-outline-info active py-1"
                    data-toggle="tab"
                    role="tab"
                    :aria-controls="'pt'+name+'-tab'"
                    :id="'pt'+name+'-tab'"
                >
                    Português
                </a>
            </li>
            <li class="nav-item" role="presentation">
                <a
                    :href="'#en'+name"
                    class="nav-link btn-outline-info py-1"
                    data-toggle="tab"
                    role="tab"
                    :aria-controls="'en'+name+'-tab'"
                    :id="'en'+name+'-tab'"
                >
                    Inglês
                </a>
            </li>
            <li class="nav-item" role="presentation">
                <a
                    :href="'#es'+name"
                    class="nav-link btn-outline-info py-1"
                    data-toggle="tab"
                    role="tab"
                    :aria-controls="'es'+name+'-tab'"
                    :id="'es'+name+'-tab'"
                >
                    Espanhol
                </a>
            </li>
        </ul>
        <div class="tab-content">
            <div class="pt-2 tab-pane fade active show" :id="'pt'+name">
                <div class="form-group">
                    <textarea
                        name="pt"
                        :class="[{ 'is-invalid' : error }, className,]"
                        :minlength="minLength"
                        :maxlength="maxLength"
                        :required="required"
                        :placeholder="placeholder"
                        :rows="rows"
                        :value="value.pt"
                        :disabled="disabled"
                        @input="updateValue"
                        :style="'resize: ' + resize"
                    ></textarea>
                </div>
            </div>
            <div class="pt-2 tab-pane fade" :id="'en'+ name">
                <div class="form-group">
                    <textarea
                        name="en"
                        :class="[{ 'is-invalid' : error }, className,]"
                        :minlength="minLength"
                        :maxlength="maxLength"
                        :required="required"
                        :placeholder="placeholder"
                        :rows="rows"
                        :value="value.en"
                        :disabled="disabled"
                        @input="updateValue"
                        :style="'resize: ' + resize"
                    ></textarea>
                </div>
            </div>
            <div class="pt-2 tab-pane fade" :id="'es'+name">
                <div class="form-group">
                    <textarea
                        name="es"
                        :class="[{ 'is-invalid' : error }, className,]"
                        :minlength="minLength"
                        :maxlength="maxLength"
                        :required="required"
                        :placeholder="placeholder"
                        :rows="rows"
                        :value="value.es"
                        :disabled="disabled"
                        @input="updateValue"
                        :style="'resize: ' + resize"
                    ></textarea>
                </div>
            </div>
            <div v-if="error" class="invalid-feedback">
				{{ error }}
			</div>
        </div>
    </div>
</template>

<script>
	export default {
		props: {
            containerId: String,
			containerClass: { type: String, default: 'col-12 col-xl-3 col-md-6' },
			className: { type: String, default: 'form-control' },
			error: String,
            name: String,
            placeholder: String,
            required: Boolean,
            disabled: Boolean,
            value: { pt: null, en: null, es: null },
            minLength: Number,
			maxLength: Number,
            rows: { type: Number, default: 5 },
            resize: { type: String, default: 'both'},
		},
		methods: {
			updateValue(e) {
				this.value[e.target.name] = e.target.value;
                this.$emit('input', this.value);
			}
		}
	}
</script>