<template>
	<div
		ref="modal"
		class="modal fade"
		tabindex="-1"
		role="dialog"
	>
		<div
			class="modal-dialog modal-dialog-centered modal-xl"
			role="document"
		>
			<div class="modal-content">
				<div class="modal-header">
                    <h5 class="modal-title">
						<i class="fa fa-list-ol"></i>
						Ranking
					</h5>
                    <button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
					>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
				<div class="modal-body">
					<div class="card">
						<div class="card-header">
							<div class="row">
								<app-select
									containerClass="col-6"
									className="custom-select form-control form-control-sm"
									formGroup=""
									title="Escolas"
									empty="Todas"
									:options="schools"
									v-model="schoolID"
									:dataLiveSearch="true"
									:callback="loadClassrooms"
								/>
								<app-select
									containerClass="col-6"
									className="custom-select form-control form-control-sm"
									formGroup=""
									title="Salas"
									empty="Todas"
									:options="classrooms"
									v-model="classroomID"
									:dataLiveSearch="true"
								/>
							</div>
						</div>
						<div class="card-body">
							<div v-if="loading" class="col-12 d-flex justify-content-center">
								<img src="/assets/img/loading.svg">
							</div>
							<div v-if="!loading" class="col-12">
								<table class="table table-striped">
									<thead>
										<th><i class="fa fa-trophy"></i></th>
										<th>Nome</th>
										<th>Escola</th>
										<th>Partidas</th>
										<th>Pontos</th>
									</thead>
									<tbody>
										<tr v-for="(r, i) in ranking">
											<td>{{ r.position }}</td>
											<td>{{ r.player.nickname }}</td>
											<td>{{ (r.player.school).name }}</td>
											<td>{{ r.player.battles.length }}</td>
											<td>{{ r.score }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="card-footer">
							<app-pagination
								ref="pagination"
								v-model="currentPage"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Select from './forms/SelectComponent';
	import Pagination from './PaginationComponent';

	export default {
		components: {
			'app-select': Select,
			'app-pagination': Pagination,
		},
		data() {
			return {
				modal: null,
				loading: false,
				schools: [],
				classrooms: [],
				schoolID: null,
				classroomID: null,
				ranking: [],
				currentPage: 1,
			};
		},
		watch: {
			schoolID: function (newValue, oldValue) {
				this.classroomID = null;
				this.loadRanking();
			},
			classroomID: function (newValue, oldValue) {
				this.loadRanking();
			},
			currentPage: function (newValue, oldValue) {
				this.loadRanking();
			}
		},
		mounted() {
			this.modal = $(this.$refs.modal);
		},
		methods: {
			open() {
				this.modal.modal('show');
				this.loadSchools();
				this.loadRanking();
			},
			loadSchools() {
				axios.get(`/web/schools/list?toTags=true`)
					.then(res => {
						this.schools = res.data;

						if (this.schools.length == 1) {
                        	this.schoolID = this.schools[0].value;
							this.loadClassrooms(this.schoolID);
						}
					})
					.catch(err => {});
			},
			loadClassrooms(schoolID) {
				this.classrooms = [];

				if (!schoolID) return;

				axios.get(`/web/classrooms/findBySchool/${schoolID}?toTags=true`)
					.then(res => {
						this.classrooms = res.data;

						if (this.classrooms.length == 1) {
                        	this.classroomID = this.classrooms[0].value;
							this.loadRanking();
						}
					})
					.catch(err => {});
			},
			loadRanking() {
				let params = { limit: 10, page: this.currentPage };

				if (this.schoolID) params['school_id'] = this.schoolID;
				if (this.classroomID) params['classroom_id'] = this.classroomID;

				this.loading = true;

				axios.get(`/web/ratings`, { params })
					.then(res => {
						this.ranking = res.data.data.map((r, i) => {
							if (!r.player.school) {
								r.player.school = { name: '-' };
							}

							r.position = `${(params.limit * (params.page - 1)) + (i + 1)}º`;

							return r;
						});

						this.loading = false;
						this.$refs.pagination.load(res.data.last_page);
					})
					.catch(err => {});
			}
		}
	}
</script>