<template>
	<div v-if="tournament">
		<app-page-header
			icon="fa fa-trophy"
			:title="'Editar Torneio: ' + tournament.id"
			:breadcrumbs="[
				{ href: '/', title: 'Painel' },
                { href: '/tournaments', title: 'Torneios' },
                { href: '', title: 'Editar' },
			]"
		/>

		<div class="row">
			<div class="col-md-12">
				<div class="tile">
					<div class="tile-body">
						<form ref="form">
							<div class="row">
								<app-select
									label="Tipo"
									title="Tipo"
									:required="true"
                                    :options="tournamentTypes"
									v-model="tournament.tournament_type_id"
									:disabled="true"
								/>
								<app-input
									title="máximo 80 caracteres"
									label="Título"
									placeholder="Título"
                                    :maxLength="80"
									:minLength="2"
									:required="true"
									v-model="tournament.title"
									:disabled="saving"
								/>
								<app-tournament-stage-create
									type="edition"
									v-model="tournament.stages"
									:disabled="saving"
								/>
								<div class="col-12">
									<label for="desempate">Critérios de desempate</label>
									<div class="card">
										<div
											class="card-body"
										>
											<app-toggle
												v-for="tiebreaker in tiebreakerTypes"
												:key="tiebreaker.id"
												containerClass="col-12 col-xl-3 col-lg-4 col-md-6 p-0"
												subClass="d-flex justify-content-between align-items-center m-0"
												:label="tiebreaker.title"
												:value="tiebreaker.id"
												v-model="tournament.tiebreaker_type_ids"
												:disabled="true"
											/>
										</div>
									</div>
								</div>
							</div>
							<hr />
							<div class="row">
								<div class="col-12">
									<app-button
										type="update"
										:callback="onSubmit"
										color="primary"
										:disabled="saving"
									/>
                                    <app-link
										type="back"
										:url="backUrl"
										:disabled="saving"
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import PageHeader from '../PageHeaderComponent';
	import Select from '../forms/SelectComponent';
	import Input from '../forms/InputComponent';
	import Tree from '../forms/TreeComponent';
	import TournamentStageCreate from './TournamentStageCreateComponent';
	import Toggle from '../forms/ToggleComponent';
	import Button from '../forms/ButtonComponent';
	import Link from '../forms/LinkComponent';

	export default {
		components: {
			'app-page-header': PageHeader,
			'app-select': Select,
			'app-input': Input,
			'app-tree': Tree,
			'app-tournament-stage-create': TournamentStageCreate,
			'app-toggle': Toggle,
			'app-button': Button,
        	'app-link': Link,
		},
		data() {
			return {
				saving: false,
				tournamentTypes: [],
				tiebreakerTypes: [],
				states: [],
				cities: [],
				schools: [],
				tournament: null
			};
		},
		props: {
			id: Number,
			backUrl: String,
		},
		mounted() {
			Preloader.show();
			this.loadTournamentTypes(() => {
				this.loadTiebreakerTypes(() => {
					this.load();
				});
			});
		},
		methods: {
			loadTournamentTypes(callback) {
				axios.get('/web/tournament_types/list?toTags=true')
					.then(res => {
						this.tournamentTypes = res.data;
						callback();
					})
					.catch(err => {});
			},
			loadTiebreakerTypes(callback) {
				axios.get('/web/tiebreaker_types/list')
					.then(res => {
						this.tiebreakerTypes = res.data;
						callback();
					})
					.catch(err => { });
			},
			load() {
				axios.get(`/web/tournaments/${this.id}`)
					.then(res => {
						this.tournament = res.data;
						this.tournament.tiebreaker_type_ids = res.data.tiebreaker_types
							.map(tbt => tbt.id);

						delete this.tournament.tiebreaker_types;
						delete this.tournament.tournament_type;

						for (let stage of this.tournament.stages) {
							stage.starts_in = new Date(stage.starts_in)
								.toLocaleString()
								.replace(',','');
							stage.ends_in = new Date(stage.ends_in)
								.toLocaleString()
								.replace(',', '');
						}

						Preloader.hide();
					})
					.catch(err => {});
			},
			onSubmit() {
				this.saving = true;
				let validate = this.validateForm(this.$refs.form);

				if (validate != '') {
					Flash('error', validate);
					this.saving = false;
				} else if (!this.tournament.stages.length) {
					Flash('error', 'Crie pelo menos uma fase!');
					this.saving = false;
				} else {
					axios.put(`/web/tournaments/${this.id}`, this.tournament)
						.then(res => {
							Swal.fire({
								title: 'Torneio atualizado com sucesso!',
								type: 'success'
							})
							.then(() => {
								window.location.href = '/tournaments';
							});
						})
						.catch(err => {
							Flash('error', 'Erro ao tentar atualizar torneio!');
							this.saving = false;
						});
				}
			}
		}
	}
</script>