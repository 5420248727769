var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-bell","title":"Notificações","breadcrumbs":[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Notificações' },
            ]}}),_vm._v(" "),_c('app-datatable',{attrs:{"entity":"notifications","allow-enable-multi":_vm.allows.enableMulti,"allow-disable-multi":_vm.allows.disableMulti,"allow-delete-multi":_vm.allows.deleteMulti,"statusFilterIndex":5,"options":{
                ajax: {
                    url: '/web/notifications'
                },
                serverSide: false,
                columns: this.columns
            },"actions":{
				create: {
                    allow: this.allows.create,
                    url: '/notifications/create',
                },
                show: {
                    allow: this.allows.show,
                    url: '*',
                    callback: _vm.onShow
                },
                edit: {
                    allow: this.allows.edit,
                    url: '/notifications/*/edit',
                },
            }}}),_vm._v(" "),_c('app-notification-show',{ref:"notificationShow"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }