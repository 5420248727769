<template>
	<div
		class="tab-pane fade"
		id="step11"
		role="tabpanel"
	>
		<h3 class="text-center">
			Revise as informações e crie o torneio!
		</h3>
		<table
			class="table table-sm table-striped"
		>
			<tbody v-if="tournament">
				<tr>
					<th>Nome</th>
					<td>{{ tournament.title }}</td>
				</tr>
				<tr>
					<th>Tipo</th>
					<td>{{ tournamentType }}</td>
				</tr>
				<tr>
					<th colspan="2">Salas participantes</th>
				</tr>
				<tr>
					<td colspan="2">
						<div class="row">
							<div
								v-for="c of classrooms"
								class="col-12 col-md-6"
							>
								{{ c.label }}
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td colspan="2">
						<ul class="list-group" id="review">
							<li
								v-for="(stage, i) of tournament.stages"
								class="list-group-item"
							>
								<a
									:href="'#review-stage' + i"
									data-toggle="collapse"
									role="button"
									class="form-control text-info collapsed"
								>
									<span>{{ stage.title }}</span>
									<span class="arrow-collapse">
										<i class="fa fa-angle-down rotate-arrow"></i>
									</span>
								</a>
								<div
									class="collapse"
									:id="'review-stage' + i"
									data-parent="#review"
								>
									<table class="table table-sm table-striped">
										<tbody>
											<tr>
												<th>Quando começa?</th>
												<td>{{ stage.starts_in }}</td>
											</tr>
											<tr>
												<th>Quando termina?</th>
												<td>{{ stage.ends_in }}</td>
											</tr>
											<tr>
												<th>Partidas para se classificar?</th>
												<td>{{ stage.min_battles }}</td>
											</tr>
											<tr>
												<th>Quantos podem se classificar por divisão?</th>
												<td>{{ stage.next_stage_amount }}</td>
											</tr>
											<tr>
												<th>Qual tipo de divisão?</th>
												<td>
													{{ getDivisionType(stage.next_stage_type) }}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</li>
						</ul>
					</td>
				</tr>
				<tr>
					<th colspan="2">Critérios de desempate</th>
				</tr>
				<tr>
					<td colspan="2">
						{{ tiebreakerTypes }}
					</td>
				</tr>
			</tbody>
		</table>
		<div class="text-center">
			<button
				class="btn btn-outline-info bt-confirm"
				@click="onSave"
			>
				CRIAR TORNEIO
			</button>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				divisionTypes: [],
				tournamentType: '-',
				classrooms: [],
				tiebreakerTypes: '-'
			};
		},
		props: {
			tournament: null
		},
		mounted() {
			this.loadDivisionTypes();
		},
		methods: {
			getDivisionType(type) {
				for (let t of this.divisionTypes) {
					if (t.value == type) {
						return t.label;
					}
				}
			},
			open() {
				this.loadTournamentTypes();
				this.loadClassrooms();
				this.loadDivisionTypes();
				this.loadTiebreakerTypes();
			},
			loadTournamentTypes() {
				axios.get('/web/tournament_types/list?toTags=true')
					.then(res => {
						for (let type of res.data) {
							if (type.value == this.tournament.tournament_type_id) {
								this.tournamentType = type.label;
								break;
							}
						}
					})
					.catch(err => {});
			},
			loadClassrooms() {
				const params = { ids: this.tournament.classroom_ids };
				axios.get('/web/classrooms/list?toTags=true', { params })
					.then(res => {
						this.classrooms = res.data;
					})
					.catch(err => {});
			},
			loadDivisionTypes() {
				axios.get('/web/tournament_stages/division_types?toTags=true')
					.then(res => {
						this.divisionTypes = res.data;
					})
					.catch(err => {});
			},
			loadTiebreakerTypes() {
				axios.get('/web/tiebreaker_types/list?toTags=true')
					.then(res => {
						let value = [];
						for (let tbt of res.data) {
							if (this.tournament.tiebreaker_type_ids.includes(tbt.value)) {
								value.push(`${tbt.label}`);
							}
						}
						
						this.tiebreakerTypes = value.join(', ');
					})
					.catch(err => { });
			},
			onSave() {
				this.$emit('save');
			}
		}
	}
</script>