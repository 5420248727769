<template>
	<div :class="containerClass">
		<a
			:href="link"
			:class="[
				'widget-small success mb-0 coloured-icon btn-widget',
				type,
				{ 'cursor-pointer': hasCallback }
			]"
			:data-content="dataContent"
			:data-toggle="dataToggle"
			@click="callback"
		>
			<i :class="['icon', icon, 'fa-3x']"></i>
			<div class="info">
				<h4>{{ title }}</h4>
				<b v-if="value || value == 0" class="counter">{{ value }}</b>
			</div>
		</a>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				hasCallback: false
			};
		},
		props: {
			containerClass: { type: String, default: 'col-12 col-xl-3 col-md-6 col-sm-6 mb-4' },
			type: { type: String, default: 'info' },
			icon: { type: String, default: 'fa fa-question' },
			title: { type: String, default: 'title' },
			dataContent: String,
			dataToggle: String,
			link: String,
			value: String | Number
		},
		mounted() {
			this.hasCallback = this.$listeners.click !== undefined;

			config.loadPopover();
		},
		methods: {
			callback() {
				this.$emit('click');
			}
		}
	}
</script>