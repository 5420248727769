<template>
	<div class="tab-pane fade" id="step2" role="tabpanel">
		<h3 class="text-center">Qual o nome do torneio?</h3>
		<p class="text-center">
			Crie um nome para o seu torneio.
		</p>
		<p class="text-center">
			É sugerido que o nome identifique a situação em que se dará
			o torneio.
			<br>
			Exemplos: "Torneio da semana..." ou "Torneio do prof. ...".
		</p>
		<app-input
			ref="input"
			containerClass=""
			:required="true"
			:minLength="2"
            :maxLength="80"
			title="mínimo 2, máximo 80 caracters"
            placeholder=" Digite o nome do torneio"
			@input="updateValue"
		/>
		<app-arrows
			:disabled="!btNextEnabled"
			@click="onClickArrow"
		/>
	</div>
</template>

<script>
	import Input from '../../forms/InputComponent';
	import Arrows from './ArrowsComponent';

	export default {
		components: {
			'app-input': Input,
			'app-arrows': Arrows,
		},
		model: {
			prop: 'modelValue',
			event: 'input',
		},
		data() {
			return {
				btNextEnabled: false
			};
		},
		methods: {
			onClickArrow(direction) {
				this.$emit('click', direction);
			},
			updateValue(value) {
				this.$emit('input', value);

				const field = $(this.$refs.input.$el).find('input');
				this.btNextEnabled = this.validate(field);
			}
		}
	}
</script>