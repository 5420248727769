<template>
    <div>
        <app-page-header
            icon="fa fa-object-group"
            title="Cadastrar Categoria"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '/categories', title: 'Categorias' },
                { href: '', title: 'Cadastrar' },
            ]"
        />

        <div class="row">
            <div class="col-12">
                <div class="tile">
                    <div class="tile-body">
                        <form ref="form">
                            <div class="row">
                                <app-input-multi
                                    name="title"
                                    placeHolder="Título"
                                    :minLength="1"
                                    :maxLength="65"
                                    :required="true"
                                    v-model="category.title"
                                    :disabled="saving"
                                />
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-12">
                                    <app-button 
                                        type="create" 
                                        :callback="onSubmit" 
                                        color="primary"
                                        :disabled="saving"
                                    />
                                    <app-link
                                        type="back"
                                        :url="backUrl"
                                        :disabled="saving"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import InputMulti from '../forms/InputMultiLanguageComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-input-multi': InputMulti,
            'app-button': Button,
            'app-link': Link,
        },
		data() {
			return {
                saving: false,
				category: {
					title: { pt: null, en: null, es: null }
				}
			}
		},
        props: {
			backUrl: String,
		},
        methods: {
            onSubmit() {
                this.saving = true;
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                    this.saving = false;
                } else {
                    axios.post(`/web/categories`, this.category)
                        .then(res => {
                            Swal.fire({
                                title: 'Categoria salva com sucesso!',
                                type: 'success',
                                allowOutsideClick: false,
                            })
                            .then(result => {
                                window.location.href = '/categories';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar criar registro!');
                            this.saving = false;
                        });
                }
            }
		}
    }
</script>