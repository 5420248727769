<template>
	<div>
		<app-page-header
            icon="fa fa-trophy"
            title="Torneios"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Torneios' },
            ]"
        />

		<app-datatable
            entity="tournaments"
            :allow-enable-multi="allows.enableMulti"
            :allow-disable-multi="allows.disableMulti"
            :allow-delete-multi="allows.deleteMulti"
            :options="{
                ajax: {
                    url: '/web/tournaments'
                },
                columns: this.columns
            }"
            :actions="{
                show: {
                    allow: this.allows.show,
                    url: '*',
                    callback: onShow
                },
                edit: {
                    allow: this.allows.edit,
                    url: '/tournaments/*/edit',
                },
				delete: {
                    allow: this.allows.delete,
                    url: '*',
                },
                ranking: {
                    allow: this.allows.ranking,
                    url:  '*',
                    callback: showRanking
                },
                statistics: {
                    allow: this.allows.statistics,
                    url:  '*',
                    callback: showStatistic
                },
            }"
            :filters="[
                {
                    label: 'Status',
                    name: 'status',
                    index: 4,
                    type: 'select',
                    options: statusList
                }
            ]"
        />

		<app-tournament-show ref="tournamentShow" />
        <app-tournament-ranking ref="tournamentRanking" />
        <app-tournament-statistic ref="tournamentStatistic" />
        <app-wizard ref="wizard" />
        
        <app-link
            v-if="this.allows.create"
            type="create"
            data-toggle="popover"
			data-content="Cadastrar Novo"
			title=""
            :onClick="onCreate"
        />
	</div>
</template>

<script>
	import PageHeader from '../PageHeaderComponent';
	import DataTable from '../DataTableComponent';
	import TournamentShow from './TournamentShowComponent';
    import TournamentRanking from './TournamentRankingComponent';
    import TournamentStatistic from './TournamentStatisticComponent';
    import Link from '../forms/LinkComponent';
    import Wizard from './wizard/TournamentWizardComponent';
    import ProfileType from '../../enums/ProfileType';

	export default {
		components: {
			'app-page-header': PageHeader,
			'app-datatable': DataTable,
			'app-tournament-show': TournamentShow,
            'app-tournament-ranking': TournamentRanking,
            'app-tournament-statistic': TournamentStatistic,
            'app-link': Link,
            'app-wizard': Wizard,
		},
        data() {
            return {
                statusList: []
            }
        },
		props: {
			allows: Object,
			columns: Array,
		},
        mounted() {
            this.loadStatus();
        },
		methods: {
            loadStatus() {
                axios.get('/web/tournaments/status')
                    .then(res => {
                        this.statusList = res.data;
                    })
                    .catch(err => {});
            },
			onShow(id) {
                this.$refs.tournamentShow.open(id);
			},
            showRanking(id) {
                this.$refs.tournamentRanking.open(id);
            },
            showStatistic(id) {
                this.$refs.tournamentStatistic.open(id);
            },
            onCreate() {
                if (PROFILE_CODE == ProfileType.TEACHER) {
                    this.$refs.wizard.open();
                } else {
                    window.location.href = '/tournaments/create';
                }
            }
		}
	}
</script>
