(function(cash) {
	"use strict";

    if (!localStorage.getItem('menuState')) {
		localStorage.setItem('menuState', 'show');
	}

    if (localStorage.getItem('menuState') === 'show') {
		cash('body').removeClass('sidenav-toggled');
	} else {
		cash('body').addClass('sidenav-toggled');
	};

    $(document).ajaxError((event, jqxhr, settings, thrownError) => {
		if (jqxhr.status == 401) {//Session Expired
			Swal.fire({
		        type: 'info',
		        title: 'Opss!',
		        text: 'Sua sessão expirou!',
		        confirmButtonText: 'Entrar Novamente',
		    }).then((response) => {
		        if (response.value) {
		            window.location.href = '/login';
		        }
		    });
		}
	});

    $('[data-toggle="tooltip"]').tooltip();

    //FIX FILE NAME IN SELECT FILE FIELDS
	$('input:file').change(function() {
		var fileName = cash(this).val().replace('C:\\fakepath\\', '');
		cash(cash(this).parent().children('.custom-file-label')).html(fileName);
	});

    window.config = {
		loadPopover: () => {
			$('[data-toggle="popover"],[data-toggle-second="popover"]')
				.not('.lazy')
				.popover({
					trigger: 'hover',
					placement: 'bottom',
					html: true
				});
		},
		resetPopovers() {
			$('[data-toggle="popover"],.toggle-popover').popover('dispose');
		}
    };

    window.config.loadPopover();
})(cash);