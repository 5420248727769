<template>
    <div>
        <app-page-header
            icon="fa fa-gamepad"
            title="Cadastrar Desafio"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '/challenges', title: 'Desafios' },
                { href: '', title: 'Cadastrar' }
            ]"
        />

        <div class="row">
            <div class="col-12">
                <div class="tile">
                    <div class="tile-body">
                        <form ref="form">
                            <div class="row">
                                <app-select
									containerClass="col-12 col-xl-4 col-md-6"
                                    name="category_id"
                                    label="Categoria"
                                    title="Categoria"
                                    :required="true"
                                    :options="categories"
                                    v-model="challenge.category_id"
									:disabled="saving"
                                />
                                <app-input-multi
									containerClass="col-12 col-xl-4 col-md-6"
                                    name="title"
                                    placeHolder="Título"
                                    :minLength="1"
                                    :maxLength="65"
                                    :required="true"
									:disabled="saving"
									v-model="challenge.title"
                                />
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-12">
                                    <app-button 
                                        type="create" 
                                        :callback="onSubmit" 
                                        color="primary"
										:disabled="saving"
                                    />
                                    <app-link
										type="back"
										:url="backUrl"
										:disabled="saving"
									/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Select from '../forms/SelectComponent';
    import InputMulti from '../forms/InputMultiLanguageComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-select': Select,
            'app-input-multi': InputMulti,
            'app-button': Button,
            'app-link': Link,
        },
        data() {
			return {
				saving: false,
				challenge: {
					category_id: null,
					title: { pt: null, en: null, es: null }
				},
                categories: [],
			}
		},
        props: {
			backUrl: String,
		},
        mounted() {
            this.loadCategories();
        },
        methods: {
			loadCategories() {
                axios.get(`/web/categories/list?toTags=true`)
					.then(res => {
						this.categories = res.data;
					})
					.catch(err => {});
            },
            onSubmit() {
                this.saving = true;
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
					this.saving = false;
                } else {
                    axios.post(`/web/challenges`, this.challenge)
                        .then(res => {
                            Swal.fire({
                                title: 'Desafio salvo com sucesso!',
                                type: 'success'
                            })
                            .then(result => {
                                window.location.href = '/challenges';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar criar desafio!');
							this.saving = false;
                        });
                }
            }
		}
    }
</script>