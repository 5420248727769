<template>
    <div>
        <app-page-header
            icon="fa fa-user"
            title="Jogadores"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Jogadores' },
            ]"
        />

        <app-datatable
            ref="dataTable"
            entity="players"
            :allow-enable-multi="allows.enableMulti"
            :allow-disable-multi="allows.disableMulti"
            :allow-delete-multi="allows.deleteMulti"
            :options="{
                ajax: {
                    url: '/web/players'
                },
                columns: this.columns
            }"
            :actions="{
                show: {
                    allow: this.allows.show,
                    url: '*',
                    callback: onShow
                },
				delete: {
                    allow: this.allows.delete,
                    url: '*',
                },
                performance: {
                    allow: true,
                    url: '*',
                    callback: onPerformance
                },
            }"
            :filters="[
                {
                    label: 'Escola',
                    name: 'school_id',
                    type: 'select',
                    index: 4,
                    options: schools,
                    liveSearch: true,
                    callback: loadClassrooms
                },
                {
                    label: 'Sala',
                    name: 'classroom_id',
                    type: 'select',
                    index: 5,
                    options: classrooms,
                    liveSearch: true,
                },
                {
                    name: 'player_type',
                    type: 'select',
                    label: 'Tipo de jogador',
                    index: 6,
                    options: [
                        { value: 'mn', label: 'MN' },
                        { value: 'common', label: 'Comum' },
                    ]
                }
            ]"
        />

        <app-player-show ref="playerShow" />
        <app-player-performance ref="playerPerformance" />
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
	import DataTable from '../DataTableComponent';
    import PlayerShow from './PlayerShowComponent';
    import PlayerPerrformance from './PlayerPerformanceComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
			'app-datatable': DataTable,
            'app-player-show': PlayerShow,
            'app-player-performance': PlayerPerrformance
		},
        data() {
            return {
                loading: false,
                schools: [],
                classrooms: [],
            }
        },
		props: {
			allows: Object,
			columns: Array
		},
        mounted() {
            this.loading = true;
            Preloader.show();
            this.loadSchools();
        },
		methods: {
            loadSchools() {
                axios.get('/web/schools/list?toTags=true')
                    .then(res => {
                        this.schools = res.data;
                        this.loading = false;
                        Preloader.hide();
                    })
                    .catch(err => {});
            },
            loadClassrooms(data) {
                axios.get(`/web/classrooms/findBySchool/${data.value}?toTags=true`)
                    .then(res => {
                        this.$refs.dataTable.setNewOptions('classroom_id', res.data);
                    })
                    .catch(err => {});
            },
			onShow(id) {
				this.$refs.playerShow.open(id);
			},
            onPerformance(id) {
                this.$refs.playerPerformance.open(id);
            }
		}
    }
</script>