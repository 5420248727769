<template>
	<div
		class="tab-pane fade"
		:id="tabName"
		role="tabpanel"
	>
		<h3 class="text-center">{{ title }}</h3>
		<p class="text-center">Considerar?</p>
		<div
			ref="radios"
			class="row"
		>
			<div class="col-12 col-md-6">
				<div class="animated-radio-button">
					<label>
						<input
							:name="name"
							type="radio"
							value="true"
							@input="updateValue"
						>
						<span class="label-text">Sim</span>
					</label>
				</div>
			</div>
			<div class="col-12 col-md-6">
				<div class="animated-radio-button">
					<label>
						<input
							:name="name"
							type="radio"
							value="false"
							@input="updateValue"
						>
						<span class="label-text">Não</span>
					</label>
				</div>
			</div>
		</div>
		<app-arrows
			:disabled="!btNextEnabled"
			@click="onClickArrow"
		/>
	</div>
</template>

<script>
	import Arrows from './ArrowsComponent';

	export default {
		components: {
			'app-arrows': Arrows,
		},
		model: {
			prop: 'modelValue',
			value: 'input'
		},
		data() {
			return {
				btNextEnabled: false	
			};
		},
		props: {
			tabName: String,
			title: String,
			name: String,
			value: null,
			modelValue: { type: Array, required: true }
		},
		methods: {
			onClickArrow(direction) {
				this.$emit('click', direction);
			},
			updateValue(e) {
				let updatedValue = [...this.modelValue];
				
				if (e.target.value == 'true') {
					updatedValue.push(Number(this.value));
				} else {
					updatedValue = updatedValue.filter(value => value != this.value);
				}

				this.$emit('input', updatedValue);
				
				const checked = $(this.$refs.radios).find('input:checked').length;
				
				this.btNextEnabled = checked == 1;
			}
		}
	}
</script>