<template>
	<div class="col-12 mb-3">
		<div class="card">
			<div class="card-header d-flex justify-content-between align-items-center px-2">
				<div>Fases</div>
				<div>
					<app-button
						color="danger"
						icon="minus"
						dataToggle="popover"
						dataContent="Remover Fase"
						title=""
						:withTitle="false"
						:callback="remove"
						:disabled="(type == 'edition') || disabled"
					/>
					<app-button
						icon="plus"
						dataToggle="popover"
						dataContent="Adicionar Fase"
						title=""
						:withTitle="false"
						:callback="add"
						:disabled="(type == 'edition') || disabled"
					/>
				</div>
			</div>
			<div class="card-body">
				<ul class="nav nav-tabs" role="tablist">
					<li
						v-for="(stage, i) in modelValue"
						class="nav-item"
					>
						<a
							:class="[
								'nav-link btn-outline-info',
								{ 'active' : i == 0 }
							]"
							data-toggle="tab"
							:href="'#stage' + i"
							:aria-controls="'stage' + i"
							role="tab"
						>
							Fase {{ i + 1 }}
						</a>
					</li>
				</ul>
				<div class="tab-content">
					<div
						v-for="(stage, i) in modelValue"
						:id="'stage' + i"
						:class="[
							'tab-pane fade',
							{ 'show active': i == 0 }
						]"
						role="tabpanel"
						:aria-labelledby="'stage' + i + '-tab'"
					>
						<div class="row">
							<app-input
								label="Título"
								:placeholder="'Título da Fase ' + (i + 1)"
								v-model="stage.title"
								:required="true"
								:disabled="disabled"
							/>
							<app-input
								type="datetime"
								label="Inicia em"
								:placeholder="'Inicio da fase ' + (i + 1)"
								v-model="stage.starts_in"
								:required="true"
								:disabled="(type == 'edition') || disabled"
							/>
							<app-input
								type="datetime"
								label="Termina em"
								:placeholder="'Termino da fase ' + (i + 1)"
								v-model="stage.ends_in"
								:required="true"
								:disabled="(type == 'edition') || disabled"
							/>
							<div class="col-12">
								<h6 class="h6 border-bottom p-2 alert-info rounded">Regras</h6>
								<div class="row">
									<app-input
										type="number"
										label="Mínimo de partidas jogadas"
										placeholder="Mínimo de partidas jogadas"
										v-model="stage.min_battles"
										:required="true"
										:disabled="(type == 'edition') || disabled"
									/>
									<app-input
										type="number"
										label="Número de vencedores por divisão"
										placeholder="Número de vencedores por divisão"
										v-model="stage.next_stage_amount"
										:required="true"
										:disabled="(type == 'edition') || disabled"
									/>
									<app-select
										label="Tipo de divisão"
										:options="stageTypes"
										v-model="stage.next_stage_type"
										:required="true"
										:disabled="(type == 'edition') || disabled"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Button from '../forms/ButtonComponent';
	import Input from '../forms/InputComponent';
	import Select from '../forms/SelectComponent';

	export default {
		components: {
			'app-button': Button,
			'app-input': Input,
			'app-select': Select,
		},
		model: {
            prop: 'modelValue',
            event: 'change'
        },
		data() {
			return {
				stageTypes: [],
				stage: {
					title: null,
					starts_in: null,
					ends_in: null,
					min_battles: null,
					next_stage_amount: null,
					next_stage_type: null,
				}
			}
		},
		props: {
			type: { type: String, default: 'create' },
			value: null,
			disabled: Boolean,
			modelValue: { type: Array, required: true }
		},
		mounted() {
			this.loadStageTypes();
		},
		methods: {
			loadStageTypes() {
				axios.get('/web/tournament_stages/list?toTags=true')
					.then(res => {
						this.stageTypes = res.data;
					})
					.catch(err => {});
			},
			add() {
				let updatedValue = [...this.modelValue];
				updatedValue.push({...this.stage});
				this.$emit('change', updatedValue);
			},
			remove() {
				let updatedValue = [...this.modelValue];
				updatedValue.pop();
				this.$emit('change', updatedValue);
			}
		}
	}
</script>