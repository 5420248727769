<template>
	<div
        ref="modal"
        class="modal fade wizard"
        tabindex="-1"
        role="dialog"
        aria-labelledby="wizard"
        aria-hidden="true"
    >
		<div
			class="modal-dialog modal-dialog-centered modal-xl"
			role="document"
		>
			<div class="modal-content">
				<div class="modal-body row m-0 p-0">
					<app-menu-browse
						:active="navBrowseEnabled"
						:stages="tournament.stages"
						@onSelectMenu="onSelectMenu"
						@onSelectStep="onSelectTabFromMenu"
					/>
					<app-menu-mobile
						:active="!navBrowseEnabled"
						@onSelectMenu="onSelectMenu"
						@onSelectStep="onSelectTabFromMenu"
					/>
					<div class="p-3 p-md-5 col-12 col-md-8">
						<div class="tab-content h-100">
							<app-introduction @click="onArrowClick" />
							<app-type
								v-model="tournament.tournament_type_id"
								@click="onArrowClick"
							/>
							<app-name
								v-model="tournament.title"
								@click="onArrowClick"
							/>
							<app-classrooms
								v-model="tournament.classroom_ids"
								@click="onArrowClick"
							/>
							<app-stage
								v-for="(stage, i) of tournament.stages"
								:key="i"
								:id="i"
								v-model="tournament.stages[i]"
								@click="onArrowClick"
							/>
							<app-stage-finished
								@clickNewStage="createNewStage"
								@click="onArrowClick"
							/>
							<app-tiebreaker-introduction @click="onArrowClick" />
							<app-tiebreaker-radio
								tabName="step7"
								title="Número de vitórias"
								name="wins"
								value="1"
								v-model="tournament.tiebreaker_type_ids"
								@click="onArrowClick"
							/>
							<app-tiebreaker-radio
								tabName="step8"
								title="Número de derrotas"
								name="loses"
								value="2"
								v-model="tournament.tiebreaker_type_ids"
								@click="onArrowClick"
							/>
							<app-tiebreaker-radio
								tabName="step9"
								title="Número de abandonos"
								name="dropouts"
								value="3"
								v-model="tournament.tiebreaker_type_ids"
								@click="onArrowClick"
							/>
							<app-tiebreaker-radio
								tabName="step10"
								title="Número de partidas"
								name="matches"
								value="4"
								v-model="tournament.tiebreaker_type_ids"
								@click="onArrowClick"
							/>
							<app-review
								ref="review"
								:tournament="tournament"
								@save="onSubmit"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import MenuBrowse from './MenuBrowseComponent';
	import MenuMobile from './MenuMobileComponent';
	import Introduction from './IntroductionComponent';
	import Type from './TypeComponent';
	import Name from './NameComponent';
	import Classrooms from './ClassroomsComponent';
	import Stage from './StageComponent';
	import StageFinished from './StageFinishedComponent';
	import TiebreakerIntroduction from './TiebreakerIntroductionComponent';
	import TiebreakerRadio from './TiebreakerRadioComponent';
	import Review from './ReviewComponent';

	export default {
		components: {
			'app-menu-browse': MenuBrowse,
			'app-menu-mobile': MenuMobile,
			'app-introduction': Introduction,
			'app-type': Type,
			'app-name': Name,
			'app-classrooms': Classrooms,
			'app-stage': Stage,
			'app-stage-finished': StageFinished,
			'app-tiebreaker-introduction': TiebreakerIntroduction,
			'app-tiebreaker-radio': TiebreakerRadio,
			'app-review': Review,
		},
		data() {
			return {
				modal: null,
				navBrowseEnabled: true,
				STEPS_TOTAL: 11,
				STAGE_LIMIT: 3,
				STAGE_STEPS_TOTAL: 6,
				step: 0,
				stageCurrent: 0,
				stageStep: 0,
				stageBase: {
					title: null,
					starts_in: null,
					ends_in: null,
					min_battles: null,
					next_stage_amount: null,
					next_stage_type: 0,
				},
				tournament: {
					tournament_type_id: null,
					title: '',
					classroom_ids: [],
					stages: [],
					tiebreaker_type_ids: []
				}
			};
		},
		mounted() {
			this.modal = $(this.$refs.modal);
		},
		methods: {
			open() {
				this.resetPage();

				this.modal.modal('show');
				this.tournament.stages.push({ ...this.stageBase });
				this.setStepButton('step0');
				this.setTab('step0');
			},
			resetPage() {
				this.step = 0;
				this.stageCurrent = 0;
				this.stageStep = 0;
			},
			onSelectTabFromMenu(tabID) {
				let validate = this.validate();

				if (!validate || validate.step >= tabID) {
					this.step = tabID;

					if (tabID == 4) {
						this.stageCurrent = 0;
						this.stageStep = 0;
						tabID = `${tabID}-0-0`;
					}

					tabID = `step${tabID}`;
					
					this.setStepButton(tabID);
					this.setTab(tabID);
				} else {
					Flash('warning', validate.message);
				}
			},
			onArrowClick(direction) {
				let stageName = '';
				let withStage = false;

				if (direction == 'next') {
					if ([3,4].includes(this.step)) {//STAGES
						withStage = true;

						if (this.step == 3) {
							this.step = 4;
						} else {
							if (this.stageStep < this.STAGE_STEPS_TOTAL) {
								this.stageStep++;
							} else if (this.stageCurrent < this.tournament.stages.length) {
								if (this.tournament.stages.length < this.STAGE_LIMIT) {
									withStage = false;
									this.step = 5;
								} else if (
									this.tournament.stages.length == this.STAGE_LIMIT &&
									this.stageCurrent == this.tournament.stages.length - 1
								) {
									withStage = false;
									this.step = 6;
								} else {
									this.stageCurrent++;
									this.stageStep = 0;
								}
							} else {
								withStage = false;
								this.step++;
							}
						}
					} else {
						this.step++;
					}
				} else {
					if ([4,5].includes(this.step)) {//STAGES
						withStage = true;
						
						if (this.step == 5) {
							this.stageCurrent = this.tournament.stages.length - 1;
							this.stageStep = this.STAGE_STEPS_TOTAL;
							this.step = 4;
						} else {
							if (this.stageStep > 0) {
								this.stageStep--;
							} else if (this.stageCurrent > 0) {
								this.stageCurrent--;
								this.stageStep = this.STAGE_STEPS_TOTAL;
							} else {
								withStage = false;
								this.step--;
							}
						}
					} else if (this.step == 6) {//JUMP STAGE CREATE FINISHED
						withStage = true;
						this.stageCurrent = this.tournament.stages.length - 1;
						this.stageStep = this.STAGE_STEPS_TOTAL;
						this.step = 4;						
					} else {
						this.step--;
					}
				}

				stageName = (withStage) ? `-${this.stageCurrent}-${this.stageStep}` : '';

				let tabID = `step${this.step}${stageName}`;
				
				this.setStepButton(tabID);
				this.setTab(tabID);
			},
			onSelectMenu() {
				this.navBrowseEnabled = !this.navBrowseEnabled;
			},
			async createNewStage() {
				this.tournament.stages.push({ ...this.stageBase });
				this.stageCurrent++;
				this.stageStep = 0;
				this.step = 3;

				await Help.delay(300);

				this.onArrowClick('next');
			},
			setStepButton(tabID) {
				$('.progress-buttons .step')
					.each((i, o) => {
						o = $(o);
						tabID = tabID.replace(/^step(\d+)(?:-.*)?$/, '$1');

						if (o.data('id') <= tabID) {
							o.addClass('active');
						} else {
							o.removeClass('active');
						}
					});
			},
			setTab(tabID) {
				$('.tab-pane').removeClass('show active');
				config.resetPopovers();

				$(`#${tabID}`)
					.addClass('show active')
					.css({ opacity: 0.0 })
					.animate({ opacity: 1.0 }, 200)
					.find('input,select')
					.focus();

				if (tabID == 'step11') {
					this.$refs.review.open();
				}
			},
			validate() {
				if (!this.tournament.title || this.tournament.title == '') {
					return {
						step: 2,
						message: 'Digite um título para o torneio!'
					};
				}

				if (!this.tournament.classroom_ids.length) {
					return {
						step: 3,
						message: 'Selecione pelo menos uma sala participante!'
					}
				}

				for (let i = 0; i < this.tournament.stages.length; i++) {
					let s = this.tournament.stages[i];

					for (let prop of Object.keys(s)) {
						if (
							s[prop] == null ||
							(!Number.isInteger(s[prop]) && s[prop].length == 0)
						) {
							return {
								step: 4,
								message: `Preencha todos os campos da fase ${ i + 1}!`
							}
						}
					}
				}

				if (!this.tournament.tiebreaker_type_ids.length) {
					return {
						step: 6,
						message: 'Selecione pelo menos um critério de desempate!'
					}
				}

				return null;
			},
			onSubmit() {
				Preloader.show('salvando...');
				let validate = this.validate();

				if (validate) {
					Preloader.hide();
					Swal.fire({
						type: 'warning',
						title: 'Ops!',
						text: validate.message
					});
				}

				axios.post('/web/tournaments', this.tournament)
					.then(res => {
						Swal.fire({
							type: 'success',
							text: 'Tourneio criado com sucesso!'
						})
						.then(_ => {
							window.location.href = '/tournaments';
						});
					})
					.catch(err => {
						Preloader.hide();
						Flash('error', 'Error ao tentar criar torneio!');
					});
			}
		}
	}
</script>