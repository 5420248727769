<template>
    <div v-if="stage">
        <app-page-header
            icon="fa fa-list"
            :title="'Editar Fase: ' + stage.id"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '/stages', title: 'Fases' },
                { href: '', title: 'Editar' }
            ]"
        />
        
        <div class="row">
            <div class="col-md-12">
                <div class="tile">
                    <div class="tile-body">
                        <form ref="form">
                            <div class="row">
                                <div class="col-12 col-sm-3">
                                    <div class="row">
                                        <app-chessboard
                                            :editable="true"
                                            containerClass="col-12"
                                            v-model="stage.fen"
                                        />
                                    </div>
                                </div>
								<div class="col-12 col-sm-9">
									<div class="row">
										<app-select
                                            containerClass="col-12 col-xl-4 col-md-6"
                                            name="challenge_id"
                                            label="Desafio"
                                            title="Desafio"
                                            :required="true"
                                            :options="challenge"
                                            v-model="stage.challenge_id"
                                            :disabled="saving"
                                        />
										<app-input-multi
                                            containerClass="col-12 col-xl-4 col-md-6"
                                            name="title"
                                            placeHolder="Título"
                                            v-model="stage.title"
                                            :minLength="1"
                                            :maxLength="65"
                                            :required="true"
                                            :disabled="saving"
                                        />
										<app-input
                                            type="number"
                                            label="Movimentos mínimos"
                                            name="moves_min"
                                            :minLength="1"
                                            :maxLength="65"
                                            :required="true"
                                            v-model="stage.moves_min"
                                            :disabled="saving"
                                        />
										<app-input
                                            type="number"
                                            label="Movimentos máximos"
                                            name="moves_max"
                                            :minLength="1"
                                            :maxLength="65"
                                            :required="true"
                                            v-model="stage.moves_max"
                                            :disabled="saving"
                                        />
                                        <app-select
                                            name="opp_piece_type"
                                            label="Oponente"
                                            title="Oponente"
                                            :required="true"
                                            :options="opponent"
                                            v-model="stage.opp_piece_type"
                                            :disabled="saving"
                                        />
										<app-toggle
											label="Status"
											v-model="stage.active"
                                            :disabled="saving"
										/>
                                        <app-text-multi
                                            containerClass="col-12 col-xl-6"
                                            name="description"
                                            placeholder="Descrição"
                                            :minLength="1"
                                            :maxLength="255"
                                            :required="true"
                                            resize="none"
                                            v-model="stage.description"
                                            :disabled="saving"
                                        />
										<div class="col-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="objetivos">Objetivos</label>
                                                <div class="card">
                                                    <div class="card-body">
                                                        <app-checkbox
                                                            v-for="obj in objectives"
                                                            :key="obj.id"
                                                            containerClass="col-12"
                                                            placeholder="Objetivos"
                                                            name="objective_ids[]"
                                                            :label="obj.title"
                                                            :required="true"
                                                            :value="obj.id"
															v-model="stage.objective_ids"
                                                            :disabled="saving"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
									</div>
								</div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-12">
                                    <app-button 
                                        type="update" 
                                        :callback="onSubmit" 
                                        color="primary"
                                        :disabled="saving"
                                    />
                                    <app-link
                                        type="back"
                                        :url="backUrl"
                                        :disabled="saving"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Chessboard from '../ChessboardComponent'
    import Select from '../forms/SelectComponent';
    import Input from '../forms/InputComponent';
    import InputMulti from '../forms/InputMultiLanguageComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';
    import TextAreaMulti from '../forms/TextAreaMultiComponent';
    import Checkbox from '../forms/CheckboxComponent';
	import Toggle from '../forms/ToggleComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
            'app-chessboard': Chessboard,
            'app-select': Select,
            'app-input': Input,
            'app-input-multi': InputMulti,
            'app-button': Button,
            'app-link': Link,
            'app-text-multi': TextAreaMulti,
            'app-checkbox': Checkbox,
			'app-toggle': Toggle,
		},
        data() {
			return {
				stage: null,
                challenge: [],
                opponent: [],
                objectives: [],
                saving: false,
			}
		},
        props: {
			id: String,
			backUrl: String,
		},
        mounted() {
            Preloader.show();
            this.loadChallenges(() => {
                this.loadOpponent(() => {
                    this.loadObjecticves(() => {
                        this.load(this.id);
                    });
                });
            });
		},
        methods: {
            loadChallenges(callback) {
                axios.get(`/web/challenges/list?toTags=true`)
					.then(res => {
						this.challenge = res.data;
                        callback();
					})
					.catch(err => {});
            },
            loadOpponent(callback) {
                axios.get(`/web/stages/list_opponent_piece_types?toTags=true`)
					.then(res => {
						this.opponent = res.data;
                        callback();
					})
					.catch(err => {});
            },
            loadObjecticves(callback) {
                axios.get(`/web/stages/list_objectives?toTags=true`)
					.then(res => {
						this.objectives = res.data;
                        callback();
					})
					.catch(err => {});
            },
            load(id) {
                axios.get(`/web/stages/${id}`)
					.then(res => {
						const objective_ids = res.data.objectives.map(o => o.id);
						this.stage = { ...res.data, objective_ids};

                        Preloader.hide();
					})
					.catch(err => {});
			},
            isObjectiveChecked(id) {
                for (let obj of this.data.objectives) {
                    if (obj.id == id) {
                        return true;
                    }
                }

                return false;
            },
            onSubmit() {
                this.saving = true;
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                    this.saving = false;
                } else {
                    axios.put(`/web/stages/${this.id}`, this.stage)
                        .then(res => {
                            Swal.fire({
                                title: 'Atualizado com sucesso!',
                                type: 'success'
                            })
                            .then(result => {
                                window.location.href = '/stages';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar atualizar registro!');
                            this.saving = false;
                        });
                }
            }
        }
    }
</script>