<template>
	<div
		class="tab-pane fade"
		:id="tabName"
		role="tabpanel"
	>
		<div class="header-mark text-muted">Fase {{ (id + 1) }}</div>
		<h3 class="text-center">Qual o tipo de divisão?</h3>
		<div>
			<p class="text-center">Selecione o tipo de divisão entre:</p>
			<ul>
				<li>
					<b>Grupo único:</b> Se classifica ou ganha
					o torneio o "número de vencedores";
				</li>
				<li>
					<b>Grupos por sala:</b> Se classifica ou ganha
					o torneio o "número de vencedores" por sala participante;
				</li>
				<li>
					<b>Grupos por ano:</b> Se classifica ou ganha
					o torneio o "número de vencedores" de acordo com os anos letivos
					das salas participantes
				</li>
			</ul>
		</div>
		<app-select
			containerClass=""
			className="form-control"
			:required="true"
			:options="divisionTypes"
			@input="updateValue"
		/>
		<app-arrows @click="onClickArrow" />
	</div>
</template>

<script>
	import Select from '../../../forms/SelectComponent';
	import Arrows from '../ArrowsComponent';

	export default {
		components: {
			'app-select': Select,
			'app-arrows': Arrows,
		},
		model: {
			prop: 'modelValue',
			event: 'input',
		},
		data() {
			return {
				divisionTypes: []
			};
		},
		props: {
			id: Number,
			tabName: String,
			value: null
		},
		mounted() {
			this.loadDivisionTypes();
		},
		methods: {
			loadDivisionTypes() {
				axios.get('/web/tournament_stages/division_types?toTags=true')
					.then(res => {
						this.divisionTypes = res.data;
					})
					.catch(err => {});
			},
			onClickArrow(direction) {
				this.$emit('click', direction);
			},
			updateValue(value) {
				this.$emit('input', value);
			}
		}
	}
</script>